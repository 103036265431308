import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./Department.module.css";
import Doctor_1 from "../../assets/images/doctor1.png";
import DepartmentImage_1 from "../../assets/images/departmentimage1.png";
import HoverImage from "../../components/HoverImage/HoverImage";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
const imageBaseUrl = "/departments";
const doctorsImagePath = "/doctors";

// const Departments = [
//   {
//     id: 1,
//     image: DepartmentImage_1,
//     department: "Cardiology",
//     para1:
//       "Internists strive to provide compassionate care of the highest quality to their patients while promoting healthy lifestyles and disease prevention. Adult Immunization- Besides routine examination and diagnosis of diseases followed by investigation and treatment, all types of fever and infections, are also managed by the department. Specialized tests, use of rapid bacterial culture and sensitivity techniques, PCR and other investigation modalities provides the best quality support for these.",
//     para2:
//       "GG Hospital is honored with highly skilled Internists who are best at delivering their valuable services to the needy. Our Physicians who combine the science of Clinical Medicine with accessible patient care. They are considered to be the back bone of the entire range of medical specialties. They deal with prevention, diagnosis and treatment of all diseases in the adult population. ",
//     para3:
//       "Our internists have put in their years of experience in dealing with any disease either common or rare. They are specially trained to solve puzzling diagnostic problems and to handle many of the chronic illnesses. The department offers array of services from treating simple fevers to complicated clinical conditions requiring consolidated attention and referral to proper specialty if required. The medical care offered is evidence based. The doctors have a team approach in the management of complicated cases and complex multi-organ medical problems.",
//     bullets: [
//       {
//         id: 1,
//         title: "ENT",
//         desc: "They cover a whole range of diseases which include but not limited to:",
//         bullets: [
//           {
//             id: 1,
//             name: "Ear",
//           },
//           {
//             id: 2,
//             name: "Nose",
//           },
//           {
//             id: 3,
//             name: "Throat",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "ENT Surgery",
//         desc: "",
//         bullets: [
//           {
//             id: 1,
//             name: "Ear Surgery",
//           },
//           {
//             id: 2,
//             name: "Nose Surgery",
//           },
//           {
//             id: 3,
//             name: "Throat Surgery",
//           },
//         ],
//       },
//     ],
//     team: [
//       {
//         id: 1,
//         name: "Dr. Tony Parayil Joseph",
//         image: DepartmentImage_1,
//         department: "Cardiology",
//       },
//       {
//         id: 2,
//         name: "Syam",
//         image: DepartmentImage_1,
//         department: "Cardiology",
//       },
//       {
//         id: 3,
//         name: "Ravi",
//         image: DepartmentImage_1,
//         department: "Cardiology",
//       },
//       {
//         id: 4,
//         name: "Bobby",
//         image: DepartmentImage_1,
//         department: "Cardiology",
//       },
//     ],
//   },
//   {
//     id: 2,
//     image: DepartmentImage_1,
//     department: "Dentals",
//     para1:
//       "Internists strive to provide compassionate care of the highest quality to their patients while promoting healthy lifestyles and disease prevention. Adult Immunization- Besides routine examination and diagnosis of diseases followed by investigation and treatment, all types of fever and infections, are also managed by the department. Specialized tests, use of rapid bacterial culture and sensitivity techniques, PCR and other investigation modalities provides the best quality support for these.",
//     para2:
//       "We are a group of experienced physicians capable of providing cross-specialty care across a wide spectrum of diseases. The department of General Medicine is staffed by large complement of competent Doctors. The department runs on daily basis. The patient can meet the specialist doctor and seek advice at any given time through OP or Emergency. Internists are much focused on evidence-based medicine which allows them to critically appraise diagnostic and treatment options while reflecting on our ability to provide cost-effective care to their aging population.",
//     para3:
//       "Our internists have put in their years of experience in dealing with any disease either common or rare. They are specially trained to solve puzzling diagnostic problems and to handle many of the chronic illnesses. The department offers array of services from treating simple fevers to complicated clinical conditions requiring consolidated attention and referral to proper specialty if required. The medical care offered is evidence based. The doctors have a team approach in the management of complicated cases and complex multi-organ medical problems.",
//     bullets: [
//       {
//         id: 1,
//         title: "Services and Facilities",
//         desc: "They cover a whole range of diseases which include but not limited to:",
//         bullets: [
//           {
//             id: 1,
//             name: "Cardiovascular Diseases",
//           },
//           {
//             id: 2,
//             name: "Respiratory Diseases",
//           },
//           {
//             id: 3,
//             name: "Gastrointestinal Diseases",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "Special Features",
//         desc: "",
//         bullets: [
//           {
//             id: 1,
//             name: "Adult Immunisation Clinic",
//           },
//           {
//             id: 2,
//             name: "Diabetic Clinic",
//           },
//           {
//             id: 3,
//             name: "Hypertensive Clinic",
//           },
//         ],
//       },
//     ],
//     team: [
//       {
//         id: 1,
//         name: "Anju",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//       {
//         id: 2,
//         name: "Syam",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//       {
//         id: 3,
//         name: "Ravi",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//       {
//         id: 4,
//         name: "Bobby",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//     ],
//   },
//   {
//     id: 3,
//     image: DepartmentImage_1,
//     department: "Oncology",
//     para1:
//       "Internists strive to provide compassionate care of the highest quality to their patients while promoting healthy lifestyles and disease prevention. Adult Immunization- Besides routine examination and diagnosis of diseases followed by investigation and treatment, all types of fever and infections, are also managed by the department. Specialized tests, use of rapid bacterial culture and sensitivity techniques, PCR and other investigation modalities provides the best quality support for these.",
//     para2:
//       "We are a group of experienced physicians capable of providing cross-specialty care across a wide spectrum of diseases. The department of General Medicine is staffed by large complement of competent Doctors. The department runs on daily basis. The patient can meet the specialist doctor and seek advice at any given time through OP or Emergency. Internists are much focused on evidence-based medicine which allows them to critically appraise diagnostic and treatment options while reflecting on our ability to provide cost-effective care to their aging population.",
//     para3:
//       "Our internists have put in their years of experience in dealing with any disease either common or rare. They are specially trained to solve puzzling diagnostic problems and to handle many of the chronic illnesses. The department offers array of services from treating simple fevers to complicated clinical conditions requiring consolidated attention and referral to proper specialty if required. The medical care offered is evidence based. The doctors have a team approach in the management of complicated cases and complex multi-organ medical problems.",
//     bullets: [
//       {
//         id: 1,
//         title: "Services and Facilities",
//         desc: "They cover a whole range of diseases which include but not limited to:",
//         bullets: [
//           {
//             id: 1,
//             name: "Cardiovascular Diseases",
//           },
//           {
//             id: 2,
//             name: "Respiratory Diseases",
//           },
//           {
//             id: 3,
//             name: "Gastrointestinal Diseases",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "Special Features",
//         desc: "",
//         bullets: [
//           {
//             id: 1,
//             name: "Adult Immunisation Clinic",
//           },
//           {
//             id: 2,
//             name: "Diabetic Clinic",
//           },
//           {
//             id: 3,
//             name: "Hypertensive Clinic",
//           },
//         ],
//       },
//     ],
//     team: [
//       {
//         id: 1,
//         name: "Anju",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//       {
//         id: 2,
//         name: "Syam",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//       {
//         id: 3,
//         name: "Ravi",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//       {
//         id: 4,
//         name: "Bobby",
//         image: DepartmentImage_1,
//         department: "Oncology",
//       },
//     ],
//   },
// ];

function Department() {
  let { department: initialDepartment } = useParams();
  const [department, setDepartment] = useState(initialDepartment);
  const navigate = useNavigate();
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentDropDownData, setDepartmentDropDownData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);

  console.log(departmentData);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChangeDepartment = (event) => {
    const selectedValue = event.target.value;
    setDepartment(selectedValue);
    // Redirect to the selected department page
    navigate(`/departments/${selectedValue}`);
  };

  const handleDoctorProfileClick = (name) => {
    navigate(`/doctor/${name}`);
  };

  // const deptData = Departments.find((dept) => dept.department === department);

  useEffect(() => {
    // Fetches the data whenever 'department' changes
    const fetchSearchDepartmentApi = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/departments/search?Department=${department}`
        );
        setDepartmentData(response?.data?.data);
        const response2 = await axios.get(
          `${apiBaseUrl}/api/doctors/search?Department_ID=${response?.data?.data[0]?.SL_NO}`
        );
        setDoctorData(response2?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    const fetchDepartmentDataApi = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/departments/getAll`
        );
        setDepartmentDropDownData(response?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    fetchSearchDepartmentApi();
    fetchDepartmentDataApi();
  }, [department]);

  if (!departmentData) {
    // Handle the case when 'name' doesn't match any data
    return <div style={{ marginTop: "100px" }}>Department not found</div>;
  }

  const servicesData = departmentData[0]?.services;

  // Split the data into an array using the newline character '\n'
  const servicesArray = servicesData?.split("\n").filter(Boolean);

  const facilitiesData = departmentData[0]?.facilities;

  // Split the data into an array using the newline character '\n'
  const facilitiesArray = facilitiesData?.split("\n").filter(Boolean);

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        marginTop: "100px",
      }}
      className={styles.DepartmentContainer}>
      <Grid container columnGap={12}>
        <Grid item xs={12} md={4} marginTop={"30px"}>
          <Typography fontSize={"35px"} fontFamily={"Inter"} fontWeight={"400"}>
            {departmentData[0]?.Department}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} marginTop={"30px"}>
          <FormControl
            fullWidth
            sx={{
              height: "82px",
              "& .MuiSelect-root": { backgroundColor: "transparent" },
            }}>
            <InputLabel>Department</InputLabel>
            <Select
              value={department}
              label="Department"
              onChange={handleChangeDepartment}>
              {departmentDropDownData.map(({ Department }, index) => (
                <MenuItem value={Department}>{Department}</MenuItem>
              ))}

              {/* <MenuItem value="General Medicine">General Medicine</MenuItem> */}
              {/* <MenuItem value="Dentals">Dentals</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columnGap={12} marginTop={"30px"}>
        <Grid item xs={12} sm={12} md={5} lg={3}>
          <Grid container>
            <Grid item xs={12}>
              <img
                width={"100%"}
                src={`${imageBaseUrl}/${departmentData[0]?.SL_NO}.jpg`}
                onError={(e) => (e.target.src = DepartmentImage_1)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                textAlign={"justify"}
                marginTop={"30px"}>
                {departmentData[0]?.para1}
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={8}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                marginBottom={"30px"}
                textAlign={"justify"}
                marginTop={"30px"}>
                {departmentData[0]?.para1}
                {/* {deptData.para2} */}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                textAlign={"justify"}>
                {/* {deptData.para3} */}
                {departmentData[0]?.para3}
              </Typography>
            </Grid>
            {/* <Grid container spacing={6} marginTop={"5px"}>
              {deptData.bullets.map((bullet) => (
                <Grid item xs={12} sm={6} md={6} key={bullet.id}>
                  <Typography
                    color="#211E1E"
                    fontSize="24px"
                    fontFamily="Inter"
                    fontWeight="600"
                    lineHeight="1.9 "
                    letterSpacing="0.36"
                  >
                    {bullet.title}
                  </Typography>
                  <Typography
                    color="#211E1E"
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="400"
                    lineHeight="1.9 "
                    letterSpacing="0.36"
                    wordWrap="break-word"
                  >
                    {bullet.desc}
                  </Typography>
                  <List>
                    {bullet.bullets.map((subBullet) => (
                      <ListItem sx={{ padding: 0 }} key={subBullet.id}>
                        <ListItemIcon sx={{ minWidth: "32px" }}>
                          <CheckIcon
                            style={{
                              fontSize: "18px",
                              marginRight: "8px",
                              color: "#4FB92A",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          color="#211E1E"
                          fontSize="18px"
                          fontFamily="Inter"
                          fontWeight="400"
                          lineHeight="2.7"
                          letterSpacing="0.36"
                          wordWrap="break-word"
                        >
                          {subBullet.name}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid> */}
          </Grid>
          <Grid container marginTop={"30px"}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="24px"
                fontFamily="Inter"
                fontWeight="600">
                Services
              </Typography>
              <ul>
                {servicesArray?.map((service, index) => {
                  const [mainService, subContents] = service.split("||");
                  const subContentsArray = subContents
                    ? subContents.split("|").map((sub) => sub.trim())
                    : [];

                  return (
                    <li key={index}>
                      <Typography
                        color="#211E1E"
                        fontSize="18px"
                        fontFamily="Inter"
                        fontWeight="400">
                        {mainService}
                      </Typography>
                      {subContentsArray.length > 0 && (
                        <ul style={{ paddingLeft: "20px" }}>
                          {subContentsArray.map((subContent, subIndex) => (
                            <li key={`${index}-${subIndex}`}>
                              <Typography
                                color="#211E1E"
                                fontSize="16px"
                                fontFamily="Inter"
                                fontWeight="400">
                                {subContent}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="24px"
                fontFamily="Inter"
                fontWeight="600">
                Facilities
              </Typography>
              <ul>
                {facilitiesArray?.map((facility, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {facility}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography
            marginTop="110px"
            fontFamily="Inter"
            fontSize="35px"
            fontWeight="700"
            letterSpacing="0.7px"
            lineHeight="133.523%"
            textAlign="center">
            Meet Our Team
          </Typography>
          <Grid
            container
            spacing={5}
            display="flex"
            justifyContent="center"
            sx={{ marginTop: "10px", marginBottom: "36px" }}>
            {doctorData.map(({ GG_ID, Name }, index) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={GG_ID}>
                <Grid container>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <HoverImage
                      image={`${doctorsImagePath}/${GG_ID}.jpg`}
                      width={"320px"}
                      height={"350px"}
                      btnName={"View Profile"}
                      objectFit="scale-down"
                      onButtonClick={() => handleDoctorProfileClick(Name)}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="23px"
                      fontStyle="normal"
                      fontWeight="560"
                      letterSpacing="0.6px"
                      lineHeight="133.523%"
                      textAlign="center">
                      {Name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="133.523%"
                      textAlign="center"
                      sx={{ opacity: "0.5" }}>
                      {department}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Department;
