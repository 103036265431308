import React, { useState } from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

function YoutubeEmbed({ embedId, DrName, desgnL1, desgnL2 }) {
  return (
    <>
      <Card
        sx={{
          // maxWidth: 500,
          border: "1px solid black",

          border: "none",
          boxShadow: "none",
          borderRadius: "10px",
        }}>
        <CardMedia
          component="iframe"
          width="500"
          height="300"
          src={`https://www.youtube.com/embed/${embedId}`}
          style={{ borderRadius: "10px", border: "none", boxShadow: "none" }}
        />
        <CardContent>
          <Typography
            gutterBottom
            color={"#211E1E"}
            component="div"
            fontSize={"26px"}
            fontWeight={"600"}>
            {DrName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {desgnL1}
          </Typography>
          <Typography variant="body2" color="text.secondary" marginTop={"10px"}>
            {desgnL2}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default YoutubeEmbed;
