import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

//style
import styles from "./AcademicsAndResearch.module.css";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import DnrbTab from "../../components/DrnbTab/DrnbTab";
import sendEmail from "../../utils/Email";

function AcademicsAndResearch() {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [courseName, setCourseName] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isPhoneNumberValid, setIsNumberValid] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // Email Validation
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
  };

  // Full name validation
  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);
    // Check if first name is not empty
    setIsFullNameValid(fullName.trim() !== "");
  };

  // Phone number validation
  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);
    // Assuming a 10-digit phone number
    const phoneNumberRegex = /^\d{10}$/;
    setIsNumberValid(phoneNumberRegex.test(newPhoneNumber));
  };

  const isSubmitDisabled =
    !isEmailValid ||
    !isFullNameValid ||
    !isPhoneNumberValid ||
    subject === "" ||
    message === "" ||
    courseName === "";

  // Creating Form Data
  function createFormData() {
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("number", phoneNumber);
    formData.append("emailId", emailId);
    formData.append("message", message);
    formData.append("subject", subject);
    formData.append("courseName", courseName);
    return formData;
  }

  // Submit API call
  async function submitFormData(formData) {
    await sendEmail(formData, "New Academic Registration on GG Website"); //TODO error/loading handling
    return await fetch(`${apiBaseUrl}/api/masters/addAcademics`, {
      method: "POST",
      body: formData,
    });
  }

  // Handling success
  function handleSuccess(data) {
    toast.success(data?.message);
    resetFormFields();
    resetValidationFlags();
    setIsFormSubmitted(true);
  }

  // Handling error
  function handleError() {
    toast.error("An error occurred. Please try again later.");
  }

  // Form reset
  function resetFormFields() {
    setEmailId("");
    setFullName("");
    setPhoneNumber("");
    setMessage("");
    setSubject("");
    setCourseName("");
  }

  // Reset Validations
  function resetValidationFlags() {
    setIsEmailValid(true);
    setIsFullNameValid(true);
    setIsNumberValid(true);
  }

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();

    try {
      const response = await submitFormData(formData);

      if (response.ok) {
        const data = await response.json();
        handleSuccess(data);
      } else {
        handleError();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", marginTop: "170px" }}>
      {/* <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography fontWeight="400" fontSize="4rem">
            DNB in Critical<br></br>
            <span style={{ fontWeight: "bold" }}>Care Medicine</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="195%"
            letterSpacing="0.36px"
          >
            It has been achieved through a combination of carefully chosen
            technology acquisitions and undoubtedly the result of hard work and
            consistent delivery of excellent services by our staff members. In
            the medical arena, our success is ultimately based on the medically
            indicated quality diagnostic practices that have remained true to
            the ethical principles of the medical profession.
          </Typography>
        </Grid>
      </Grid> */}
      <Grid container alignItems="center">
        <DnrbTab />
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        marginTop="100px"
        className={styles.aboutBanner}>
        <Grid item xs={12} sm={1} md={6}></Grid>
        <Grid item xs={12} sm={10} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="35px"
                fontStyle="normal"
                fontWeight="700"
                letterSpacing="0.7px">
                Register Now
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} marginTop="10px">
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                value={fullName}
                onChange={handleFullNameChange}
                error={!isFullNameValid}
                helperText={!isFullNameValid ? "Full name cannot be empty" : ""}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} marginTop="10px">
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                error={!isPhoneNumberValid}
                helperText={
                  !isPhoneNumberValid
                    ? "Please enter a valid 10-digit phone number"
                    : ""
                }
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} marginTop="10px">
              <TextField
                label="Email Id"
                variant="outlined"
                fullWidth
                value={emailId}
                onChange={handleEmailChange}
                error={!isEmailValid}
                helperText={
                  !isEmailValid ? "Please enter a valid email address" : ""
                }
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} marginTop="10px">
              <TextField
                label="Subject"
                variant="outlined"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={12} marginTop="10px">
              <TextField
                label="Course Name"
                variant="outlined"
                fullWidth
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} marginTop="10px">
              <TextField
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "6px",
                }}
                className={styles.ViewAllButton}
                disabled={isSubmitDisabled}
                onClick={handleSubmit}>
                Apply Now
              </Button>
              {/* Toast notifications */}
              <Toaster position="top-right" reverseOrder={false} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container alignItems="center">
        <DnrbTab /> */}
      {/* <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="180%"
          >
            GG Hospital is one of the fastest growing multi-super speciality
            hospitals in South Kerala equipped with ultra-modern
            state-of-the-art technological facilities and the most modern
            infrastructural support along with its committed and hard-working,
            most experienced faculties in various super specialities and they
            are excellent clinicians too. The history made by GG Hospital in the
            Modern Medicine field for the last 4 years is remarkable and equally
            beneficial for the public as well as for young medical professionals
            for the fulfilment of their academic and clinical knowledge
            development in the field of practice of medicine and practice of
            various modern medical specialities.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="180%"
          >
            GG Hospital is presently operating with,MDICU, CCU, PICU, NICU,
            SICU, DIALYSIS UNIT, SPECIALISED CHEMOTHERAPY DAY CARE UNIT, STATE
            OF THE ART THEATRE COMPLEX, CATH LAB WHICH IS PERFORMING
            INTERVENTIONAL CARDIOLOGY PROCEDURES, INTERVENTIONAL NEUROLOGY,
            NEURO RADIOLOGICAL PROCEDURES AND 24*7 EMERGENCY SERVICES.<br></br>{" "}
            GG Hospital is the best affordable hospital in South Kerala it is
            NABH accredited hospital and provides a comprehensive spectrum of
            advanced medical and surgical interventions with a perfect mix of
            Inpatient & Outpatient services to patients of all age groups.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            lineHeight="100%"
          >
            Functionaries of the Hospital
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            lineHeight="100%"
          >
            Dr Sheeja G Manoj- Managing Director{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            lineHeight="100%"
          >
            Dr Jeevan MK- Medical Superintendent & DNB/DrNB Course Director{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            lineHeight="100%"
          >
            Dr T P Madhu Sudhanan – HOD, Senior Consultant -Critical Care &
            Academic Programme Coordinator{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            lineHeight="100%"
          >
            Dr Chithra Sivakumar- Academic Administrator{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginTop="50px"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            lineHeight="100%"
          >
            1.DrNB Critical Care
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            marginBottom="100px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="180%"
          >
            DrNB Critical Care Medicine or Doctorate of National Board in
            Critical Care Medicine also known as DrNB in Critical Care Medicine
            is a super speciality level course for doctors in India that is done
            by them after completion of their post-graduate medical degree
            course. The duration of this super speciality course is 3 years, and
            it focuses on the study of patients who have sustained or are at
            risk of sustaining acutely life-threatening single or multiple organ
            failures due to diseases or injury.Total Seats- 2
          </Typography>
        </Grid> */}
      {/* </Grid> */}
    </Container>
  );
}

export default AcademicsAndResearch;
