import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PatientCare.module.css";
import HoverImage from "../../components/HoverImage/HoverImage";

import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

import Doctor_1 from "../../assets/images/doctor1.png";
const imageBaseUrl = "/doctors";

function PatientCare() {
  const [department, setDepartment] = useState("");
  const [name, setName] = useState("");
  const [Specialists, setDoctorData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchDepartmentDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/departments/getAll`);
      setDepartmentData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  const fetchDoctorDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/doctors/getAll`);
      setDoctorData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchDepartmentDataApi();
    fetchDoctorDataApi();
    filterSpecialists();
  }, []);

  const [filteredSpecialists, setFilteredSpecialists] = useState([]);

  const navigate = useNavigate();

  const handleChangeDepartment = (event) => {
    setDepartment(event.target.value);
    filterSpecialists(name, event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
    filterSpecialists(event.target.value, department);
  };

  const filterSpecialists = (name, department) => {
    const filtered = Specialists.filter((specialist) => {
      const nameMatch = specialist.Name.toLowerCase().includes(
        name.toLowerCase()
      );
      const departmentMatch =
        department === "" ||
        department.toLowerCase() === "all departments" ||
        specialist.Department_ID.toLowerCase() === department.toLowerCase();
      return nameMatch && departmentMatch;
    });
    setFilteredSpecialists(filtered);
  };

  useEffect(() => {
    filterSpecialists(name, department);
  }, []);

  const handleBookAppointmentClick = (id) => {
    navigate(`/bookappointment/${id}`);
  };
  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", display: "flex", flexDirection: "column" }}>
      <Grid container className={styles.container}>
        <Grid item xs={12} sx={{ marginTop: "107px" }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="35px"
            fontStyle="normal"
            fontWeight="700"
            letterSpacing="0.75px"
            marginBottom="10px">
            Select Doctor
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              className={styles.DoctorSelectInputsection}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  height: "82px",
                  "& .MuiSelect-root": { backgroundColor: "transparent" },
                }}>
                <InputLabel>Department</InputLabel>
                <Select
                  value={
                    department !== "Department" ? department : "all departments"
                  }
                  label="Department"
                  onChange={handleChangeDepartment}
                  InputLabelProps={{
                    shrink: true,
                  }}>
                  {departmentData.map((dept) => (
                    <MenuItem value={dept.SL_NO}> {dept.Department}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={handleChangeName}
                sx={{
                  width: "100%",
                  height: "82px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: "40px", marginBottom: "36px" }}>
            {filteredSpecialists.length > 0
              ? filteredSpecialists.map(
                  ({
                    GG_ID,
                    Department_ID,
                    Name,
                    Designation,
                    Qualification,
                  }) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={GG_ID}>
                      <Grid container>
                        <Grid item xs={12}>
                          <HoverImage
                            image={`${imageBaseUrl}/${GG_ID}.jpg`}
                            width={"100%"}
                            height={"336px"}
                            btnName={"Book Appointment"}
                            objectFit="scale-down"
                            onButtonClick={() =>
                              handleBookAppointmentClick(GG_ID)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "20px" }}>
                          <Typography
                            color="var(--dark, #211E1E)"
                            fontFamily="Inter"
                            fontSize="23px"
                            fontStyle="normal"
                            fontWeight="460"
                            letterSpacing="0.6px"
                            lineHeight="133.523%"
                            textAlign="center">
                            {Name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "" }}>
                          <Typography
                            color="var(--dark, #211E1E)"
                            fontFamily="Inter"
                            fontSize="14px"
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="133.523%"
                            textAlign="center"
                            sx={{ opacity: "0.5" }}>
                            {Designation}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "" }}>
                          {/* Find the department with corresponding Department_ID */}
                          {departmentData.map((dept) => {
                            if (dept.SL_NO === Department_ID) {
                              return (
                                <Typography
                                  key={dept.SL_NO}
                                  color="var(--dark, #211E1E)"
                                  fontFamily="Inter"
                                  fontSize="14px"
                                  fontStyle="normal"
                                  fontWeight="400"
                                  lineHeight="133.523%"
                                  textAlign="center"
                                  sx={{ opacity: "1" }}>
                                  Department: {dept.Department}
                                </Typography>
                              );
                            }
                            return null;
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )
              : null}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PatientCare;
