import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
//pages

import Home from "./pages/home/Home";
import About from "./pages/about/About";
import PatientCare from "./pages/patientCare/PatientCare";
import Departments from "./pages/departments/Departments";
import AcademicsAndResearch from "./pages/academic&research/AcademicsAndResearch";
import NewsAndMedia from "./pages/news&media/NewsAndMedia";
import Careers from "./pages/careers/Careers";
import CheckUp from "./pages/PremitiveCheckUpDirectory/CheckUp";
import ContactUs from "./pages/contactus/ContactUs";
import Adminstration from "./pages/Administration/Adminstration";
import DoctorsTalk from "./pages/doctorsTalk/doctorsTalk";
import Doctor from "./pages/doctor/Doctor";
import IndividualNews from "./pages/news&media/IndividualNews";
import IndividualCareer from "./pages/careers/IndividualCareer";
import Department from "./pages/department/Department";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import BookAppointment from "./pages/bookAppointment/BookAppointment";
import Gallery from "./pages/gallery/Gallery";
import IndividualGallery from "./pages/gallery/IndividualGallery";
import ENT from "./pages/department/ENT";
import IndividualBookAppointment from "./pages/bookAppointment/IndividualBookAppointment";
import RadiologyFellowship from "./pages/fellowship/RadiologyFellowship";

import Navbar from "./components/NavBar/NavBar";
import TopBar from "./components/TopBar/TopBar";

import { Container } from "@mui/material";
import Footer from "./components/footer/Footer";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function App() {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1000); // Adjust the breakpoint as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  }
  return (
    <Router>
      {/* {isLargeScreen && <TopBar />} */}
      <Container maxWidth={false} style={{ width: "95%" }}>
        <Navbar />
      </Container>

      <ScrollToTop />
      <Routes>
        {/* <Route path="/fellowship" element={<FellowshipApplication />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path="/administration/:name" element={<Adminstration />} />
        <Route path="/doctor/:name" element={<Doctor />} />

        <Route path="/patientcare" element={<PatientCare />} />
        <Route path="/departments" element={<Departments />} />

        <Route exact path="/departments/ENT" element={<ENT />} />

        <Route path="/departments/:department" element={<Department />} />

        <Route
          path="/departments/preventivecheckupdirectory"
          element={<CheckUp />}
        />
        <Route
          path="/academicsandresearch"
          element={<AcademicsAndResearch />}
        />

        <Route path="/newsandmedia" element={<NewsAndMedia />} />
        <Route path="/newsandmedia/:heading" element={<IndividualNews />} />

        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<IndividualCareer />} />

        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/doctor'stalk" element={<DoctorsTalk />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />

        <Route path="/bookappointment" element={<BookAppointment />} />
        <Route
          path="bookappointment/:id"
          element={<IndividualBookAppointment />}
        />

        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:heading" element={<IndividualGallery />} />

        {/* Add more routes for your application */}
        <Route
          path="/academicsandresearch/radiologyfellowship"
          element={<RadiologyFellowship />}
        />
      </Routes>
      <a
        href="https://bookmyopticket.com/hospital/hospital_detail1.php?hospital_id=57&hospital=GG+Hospital"
        target="_blank">
        <div className="appointment">
          <Typography fontSize="15px" fontFamily="Inter" fontWeight="600">
            Book Appointment
          </Typography>
        </div>
      </a>
      <Footer />
    </Router>
  );
}

export default App;
