import { Grid, Typography } from "@mui/material";
import React from "react";

import { FacebookEmbed } from "react-social-media-embed";
import { YouTubeEmbed } from "react-social-media-embed";
import { LinkedInEmbed } from "react-social-media-embed";
import { InstagramEmbed } from "react-social-media-embed";

function SocialMediaEmbeded() {
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        marginTop="90px">
        <Typography
          color="var(--dark, #211E1E)"
          fontFamily="Inter"
          fontSize="30px"
          fontWeight="700"
          padding="2px">
          Stay in Touch
        </Typography>
      </Grid>{" "}
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        spacing={9}
        marginTop="0px"
        marginBottom="90px">
        <Grid item>
          {" "}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FacebookEmbed
              url="https://www.facebook.com/gghospitaltvm/posts/pfbid0eFZEfugzn1Q7gcQz6MKm4WkYw979E3ZKyaxM8qBt3Y99DSsPxRtvVD2SrBEXR9Qvl"
              width={350}
              height={450}
            />
          </div>
        </Grid>
        <Grid item>
          {" "}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <LinkedInEmbed
              url="https://www.linkedin.com/embed/feed/update/urn:li:share:7140223617874784257"
              width={350}
              height={450}
            />
          </div>
        </Grid>
        <Grid item>
          {" "}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/reel/Cz27eIQhwQP/"
              width={350}
              height={450}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SocialMediaEmbeded;
