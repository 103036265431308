import React, { useState } from "react";
import { Link } from "react-router-dom";

//style
import styles from "./YoutubeVideos.module.css";

//local components

import YoutubeEmbed from "../../components/YoutubeEmbed/YoutubeEmbed";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const youtubeLinks = [
  {
    id: 1,
    name: "DR ANJU J",
    embedId: "OxhBZyzEO9o",
    desgnL1: "MBBS, DND(Paediatrics), fellowship in Neonatology (IAP)",
    desgnL2: "Sr Consultant Neonatology & Paediatrics",
  },
  {
    id: 2,
    name: "DR. ALEX ABRAHAM",
    embedId: "H3oJRa3Km9Q",
    desgnL1: "MBBS, D. Ortho,",
    desgnL2: "DNB Consultant, Orthopaedics",
  },
  {
    id: 3,
    name: "DR. SUDHEENDRA GHOSH C",
    embedId: "qqirfSnttPE",
    desgnL1: "MD(Med), MD(Chest), DNB(Resp.Med)DTCD, MPH(USA)",
    desgnL2: "Senior Consultant Pulmonology",
  },
  {
    id: 4,
    name: "DR. GAYATRI GOPAN",
    embedId: "WTfRLUWaeSA",
    desgnL1: "MBBS, MD(Gen.Medicine) DNB DM(Medical Oncology)",
    desgnL2: "Consultant Medical Oncologist",
  },
  {
    id: 5,
    name: "DR. GIREESH G K",
    embedId: "g9oVoTmJWxI",
    desgnL1: "MBBS, MD(General Medicine, DM (Cardiology)",
    desgnL2: "Senior Consultant Interventional Cardiology",
  },
  {
    id: 6,
    name: "DR. KRISHNAMOORTHY S S",
    embedId: "STxa-HvLd-A",
    desgnL1: "MBBS, DNB-Paediatrics, MRCPCH, FRCPCH",
    desgnL2: "Senior Consultant Paediatrics",
  },
];

function YoutubeVideos() {
  return (
    <Container component="main" maxWidth={false} style={{ marginTop: "40px" }}>
      <Typography
        color="#211E1E"
        fontSize="39px"
        fontFamily="Inter"
        fontWeight="400"
        wordWrap="break-word"
        display="flex"
        // justifyContent="center"
        marginBottom="50px">
        Doctor's <span style={{ fontWeight: "700" }}>Talk</span>
      </Typography>
      <Grid container marginBottom="60px" spacing={4}>
        {youtubeLinks.map(({ id, name, embedId, desgnL1, desgnL2 }, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            key={id}
            justifyContent={"center"}
            alignItems={"center"}>
            <YoutubeEmbed
              embedId={embedId}
              DrName={name}
              desgnL1={desgnL1}
              desgnL2={desgnL2}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        sx={12}
        className={styles.ViewAllButtonGrid}
        display={"flex"}
        justifyContent={"center"}>
        <Link
          to={"https://www.youtube.com/channel/UCtCjvDYpiZc46Kj_ZsdBTzg"}
          style={{ color: "white" }}>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "6px",
            }}
            className={styles.ViewAllButton}>
            Watch more Videos
          </Button>
        </Link>
      </Grid>
    </Container>
  );
}

export default YoutubeVideos;
