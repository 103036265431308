import React from "react";
import { useState, useRef, useEffect } from "react";

//mui imports
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

import styles from "./myswiper.module.css";

//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay } from "swiper/modules";

//department images
import Doctor_1 from "../../assets/images/doctor1.png";
import DepartmentImage_1 from "../../assets/images/homedepart1.png";
import DepartmentImage_2 from "../../assets/images/homedepart2.png";
import DepartmentImage_3 from "../../assets/images/homedepart3.png";
const imageBaseUrl = "/departments";

function MySwiper({ swiperRef, departments, onClickReadmore }) {
  // const swiperRef = useRef(null);

  // const handlePrevious = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev();
  //   }
  // };

  // const handleNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext();
  //   }
  // };
  const [slidesPerView, setSlidesPerView] = useState(1);
  console.log(slidesPerView);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 900) {
        setSlidesPerView(3);
      }
      if (screenWidth <= 900) {
        setSlidesPerView(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {" "}
      <div className={styles.carouselWrapper}>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={slidesPerView}
          spaceBetween={160}
          navigation={{
            prevEl: ".custom-prev",
            nextEl: ".custom-next",
          }}
          // centeredSlides={true}
          // autoplay={{ delay: 3000 }}
          // loop={true}
          autoplay={true}
          // pagination={{ clickable: true }}
          className={styles.carouselWrapper}>
          {departments.map(({ SL_NO, Department, para1 }, index) => {
            const characterLimit = 100;

            // Check if the para1 text exceeds the character limit
            const truncatedPara1 =
              para1?.length > characterLimit
                ? `${para1.slice(0, characterLimit)}...`
                : para1;

            return (
              <SwiperSlide className={styles.slide} key={SL_NO}>
                <div className={styles.whyMsigmaCarouselCard}>
                  <Grid container>
                    {" "}
                    <Grid item xs={12}>
                      <img
                        src={`${imageBaseUrl}/${SL_NO}.jpg`}
                        alt=""
                        className={styles.SwiperImage}
                        onError={(e) => (e.target.src = DepartmentImage_1)}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "20px" }}>
                      <Typography
                        fontFamily="Inter"
                        fontSize="23px"
                        fontStyle="normal"
                        fontWeight="600">
                        {Department}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "4px" }}>
                      <Typography
                        fontFamily="Inter"
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="400"
                        letterSpacing="0.36px">
                        {truncatedPara1}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "15px" }}>
                      <Typography
                        color="#17C1A3"
                        fontFamily="Inter"
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="400"
                        letterSpacing="0.36px"
                        className={styles.DotctorsTalk}
                        onClick={() => onClickReadmore({ Department })}>
                        Read More
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default MySwiper;
