import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import styles from "./RadiologyFellowship.module.css";
import sendEmail from "../../utils/Email";

// mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";

function RadiologyFellowship() {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    emailId: "",
    resume: null,
    coverLetter: "",
  });

  console.log(formData);

  const [errors, setErrors] = useState({
    phoneNumber: "",
    emailId: "",
  });

  const validatePhoneNumber = (value) => {
    const isValid = /^\d{10}$/g.test(value);
    return isValid ? "" : "Invalid phone number";
  };

  const validateEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    return isValid ? "" : "Invalid email address";
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    // Update validation errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]:
        fieldName === "phoneNumber"
          ? validatePhoneNumber(value)
          : fieldName === "emailId"
          ? validateEmail(value)
          : "",
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      resume: file,
    }));
  };

  const handleSubmit = () => {
    // Validate phone and email before submitting
    const phoneError = validatePhoneNumber(formData.phoneNumber);
    const emailError = validateEmail(formData.emailId);

    // Update validation errors
    setErrors({
      phoneNumber: phoneError,
      emailId: emailError,
    });

    if (!phoneError && !emailError) {
      sendEmail(formData);
    }
  };

  return (
    <div>
      <Container
        component="main"
        maxWidth={false}
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          marginTop: "170px",
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Typography
              color="var(--dark, #211E1E)"
              fontFamily="Inter"
              fontSize="34px"
              fontStyle="normal"
              fontWeight="600">
              FELLOWSHIP IN VASCULAR & INTERVENTIONAL RADIOLOGY
            </Typography>
          </Grid>
        </Grid>
        <Grid container marginTop="50px">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            flexDirection="column">
            <Typography
              color="var(--dark, #211E1E)"
              fontFamily="Inter"
              fontSize="30px"
              fontStyle="normal"
              fontWeight="500">
              Apply Now
            </Typography>
            <Typography
              color="var(--dark, #211E1E)"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400">
              Please complete the form below to apply for the position with us
            </Typography>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              marginTop="10px"
              marginBottom="90px">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} marginTop="10px">
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.fullName}
                    onChange={(e) =>
                      handleInputChange("fullName", e.target.value)
                    }
                    sx={{
                      height: "77px",
                      "& input": { backgroundColor: "transparent" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6} marginTop="10px">
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={formData.phoneNumber}
                    onChange={(e) =>
                      handleInputChange("phoneNumber", e.target.value)
                    }
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                    sx={{
                      height: "77px",
                      "& input": { backgroundColor: "transparent" },
                      borderColor: errors.phoneNumber ? "red" : "initial",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6} marginTop="10px">
                  <TextField
                    label="Email Id"
                    variant="outlined"
                    fullWidth
                    value={formData.emailId}
                    onChange={(e) =>
                      handleInputChange("emailId", e.target.value)
                    }
                    error={!!errors.emailId}
                    helperText={errors.emailId}
                    sx={{
                      height: "77px",
                      "& input": { backgroundColor: "transparent" },
                      borderColor: errors.emailId ? "red" : "initial",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6} marginTop="10px">
                  <TextField
                    label="Upload Resume"
                    variant="outlined"
                    type="file"
                    accept=".pdf"
                    fullWidth
                    onChange={handleFileChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: ".pdf,.docx,.doc,.txt" }}
                  />
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    sx={{ opacity: 0.4 }}>
                    Files of type .doc, .pdf, .txt, .docx
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12} marginTop="10px">
                  <TextField
                    label="Cover Letter"
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    value={formData.coverLetter}
                    onChange={(e) =>
                      handleInputChange("coverLetter", e.target.value)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  ls={12}
                  marginTop="10px"
                  display="flex"
                  justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      borderRadius: "6px",
                    }}
                    className={styles.ViewAllButton}
                    onClick={handleSubmit}>
                    Apply Now
                  </Button>
                  <Toaster position="top-right" reverseOrder={false} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg="6"></Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default RadiologyFellowship;
