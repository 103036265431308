import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./Departments.module.css";
import Doctor_1 from "../../assets/images/doctor1.png";
import DepartmentImage_1 from "../../assets/images/departmentimage1.png";
import HoverImage from "../../components/HoverImage/HoverImage";
import axios from "axios";
const imageBaseUrl = "/departments";

function Departments() {
  const navigate = useNavigate();
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchDepartmentDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/departments/getAll`);
      setDepartmentData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  const handleDepartmentClick = (department) => {
    navigate(`/departments/${department}`);
  };

  const handleChangeDepartment = (event) => {
    const selectedValue = event.target.value;
    // fetchDepartmentDataApi();
    // setSelectedDepartment(selectedValue);
    navigate(`/departments/${selectedValue}`);
  };

  useEffect(() => {
    fetchDepartmentDataApi();
  }, []);

  const filteredDepartment = selectedDepartment
    ? departmentData.filter((dep) => dep.Department === selectedDepartment)
    : departmentData;

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", display: "flex", flexDirection: "column" }}
      className={styles.container}>
      <Grid container sx={{ marginTop: "73px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="65px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="1.3px">
            GG
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="55px"
            fontStyle="normal"
            fontWeight="700"
            // letterSpacing="1.3px"
          >
            Departments
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="0.36px">
            It has been achieved through a combination of carefully chosen
            technology acquisitions and undoubtedly the result of hard work and
            consistent delivery of excellent services by our staff members. In
            the medical arena, our success is ultimately based on the medically
            indicated quality diagnostic practices that have remained true to
            the ethical principles of the medical profession.
          </Typography>
          <Grid
            item
            className={styles.DoctorSelectInputsection}
            sx={{ marginTop: "32px" }}>
            <FormControl
              fullWidth
              sx={{
                width: "100%",
                height: "82px",
                "& .MuiSelect-root": { backgroundColor: "transparent" },
              }}>
              <InputLabel>Department</InputLabel>
              <Select
                value={selectedDepartment}
                label="Department"
                onChange={handleChangeDepartment}>
                {/* <MenuItem value="all departments">All Departments</MenuItem> */}
                {departmentData.map(({ Department }, index) => (
                  <MenuItem value={Department}>{Department}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={styles.DepartmentCard} spacing={2}>
        {filteredDepartment.map(({ SL_NO, Department }, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={SL_NO}>
            <Grid container sx={{ marginTop: "151px" }}>
              <Grid item xs={12}>
                <HoverImage
                  image={`${imageBaseUrl}/${SL_NO}.jpg`}
                  width={"100%"}
                  height={"336px"}
                  objectFit="cover"
                  btnName={"View Details"}
                  onButtonClick={() => handleDepartmentClick(Department)}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Typography
                  color="var(--dark, #211E1E)"
                  fontFamily="Inter"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight="600"
                  letterSpacing="0.6px"
                  lineHeight="133.523%"
                  textAlign="center">
                  {Department}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Departments;
