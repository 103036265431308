import { Margin } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./ENT.module.css";
import Doctor_1 from "../../assets/images/doctor1.png";
import DepartmentImage_1 from "../../assets/images/departmentimage1.png";
import HoverImage from "../../components/HoverImage/HoverImage";
import axios from "axios";

const imageBaseUrl = "/departments";
const doctorsImagePath = "/doctors";
const surgeries = {
  "Major Surgeries": [
    "Cortical Mastoidectomy",
    "Tympanoplasty",
    "Ossiculoplasty",
    "Modified Radical Mastoidectomy",
    "Revision Tympanoplasty",
    "Atticotomy",
    "Wide Meatoplasty",
    "Wide Canaloplasty",
    "Exploratory Tympanotomy",
    "Myringotomy / Grommet Insertion",
    "Facial Nerve Decompression",
    "Posterior Tympanotomy",
    "External Ear & Middle Ear Reconstruction",
    "Tran labyrinthine / Retro sigmoid surgeries",
    "Stapedectomy / Stapedotomy",
    "Superior Semicircular canal dehiscence surgery",
    "Cochlear Implant surgery",
    "Combined Approach Tympanoplasty",
    "Labyrinthectomy",
    "External auditory canal Exostosis",
    "TMJ dislocation correction permanent",
    "Glomus Jugulare Surgery",
    "Glomus tympanicum Surgery",
    "Vestibular Neurectomy",
    "Acoustic neuroma Surgery",
    "CP angle tumour Surgery",
    "Facial Nerve Neuroma surgery",
    "Facial Nerve microvascular Decompression",
    "Perilymph fistula repair",
    "Cartilage Tympanoplasty",
    "Cancer of ear Surgery",
    "Tympanic neurectomy",
    "Otoplasty",
    "Auricular atresia repair",
  ],
  "Minor Surgeries": [
    "Auricular Pseudo cyst drainage",
    "Intratympanic Injection (Gentamicin / Dexona)",
    "Microscopic Examination under LA/GA",
    "Epithelial Inclusion Cyst Excision",
    "Ear Stud removal",
    "Ear lobe repair",
    "Pinna lesion Excision Biopsy",
    "Pre auricular abscess I&D",
    "Preauricular Sinus excision",
    "Foreign body ear removal under anaesthesia",
    "Keratosis obturans removal",
    "Ear piercing / Ear Lobotomy",
  ],
};
const noseSurgeries = {
  "Major Surgeries": [
    "FESS",
    "Endoscopic Sinus Surgery (ESS)",
    "Revision ESS",
    "Endoscopic MMA",
    "Nasal Polypectomy",
    "Concha Bullosa Reduction / Conchoplasty",
    "Septoplasty",
    "Endoscopic Septoplasty",
    "Endoscopic Septal Spur excision",
    "Septo Rhinoplasty",
    "Endoscopic Rhinolith Removal",
    "Endoscopic Nasopharynx Biopsy",
    "Endoscopic Sphenoid mass/ Lesion biopsy",
    "SMD / CoblationTurbinoplasty",
    "Sub mucosal / DebriderTurbinoplasty",
    "Endoscopic CSF Rhinorrhea repair",
    "Endoscopic Skull Base Surgery",
    "Transnasal Endoscopic Medial Maxillectomy",
    "Caldwell-Luc approach",
    "Sphenopalatine Artery Ligation/cauterization",
    "Endoscopic Optic Nerve Decompression",
    "Endoscopic Orbital Decompression",
    "Orbital Exenterating",
    "Lateral Rhinotomy",
    "Endoscopic DCR",
    "Endoscopic Balloon Sinuplasty",
    "Maxilla Fracture reduction",
    "Nasal bone fracture reduction",
    "Zygoma fracture reduction",
    "Mandible fracture reduction",
    "Palate fracture reduction",
    "Facio-Maxillary fracture reduction",
    "Le-forte fracture correction",
    "Orbital wall fracture reduction",
    "Endoscopic Sphenopalative Artery ligation (ESPAL)",
    "Endoscopic anterior ethmoid Artery ligation (EAAL)",
    "Choanal atresia correction",
    "Endoscopic excision and JNA",
    "Total Maxillectomy",
    "Radial Maxillectomy",
    "CranioFacial Resection (CFR)",
    "External ethmoidectomy",
    "Rhinosporidiosis Excision",
    "Nasal Hemangioma surgery",
    "Posterior Nasal Neurectomy",
    // Add more major surgeries here...
  ],
  "Minor Surgeries": [
    "Septal Abscess drainage",
    "Nasal Bone / Septal Fracture Reduction",
    "Foreign Body nose removal",
    "Excision Biopsy nasal granuloma",
    "Diagnostic Nasal Endoscopy",
    // Add more minor surgeries here...
  ],
};
const headAndNeckSurgeries = {
  "Major Surgeries": [
    "Total Thyroidectomy",
    "Hemi Thyroidectomy",
    "Selective Neck Dissection",
    "Modified Radical Neck Dissection",
    "Radical Neck Dissection",
    "Total Laryngectomy",
    "Wide Field Laryngectomy",
    "Partial Laryngectomy",
    "Superficial Parotidectomy",
    "Total Conservative Parotidectomy",
    "Total Radical Parotidectomy",
    "Submandibular Sialadenectomy",
    "Lymph Node Excision Biopsy",
    "Branchial Cyst Excision",
    "Branchial Fistula Excision",
    "Thyroglossal Cyst Excision (Sistrunk’s Operation)",
    "Tracheoplasty",
    "LTR",
    "Cystic Hygroma Excision",
    "Parapharyngeal Tumor Excision",
    "Parapharyngeal Abscess Drainage",
    "Commando Operation",
    "Carotid Body Tumor Excision",
    "Ranula Excision",
    "Blepharoplasty",
    "Gold Weight Implant for Lagophthalmosis",
    "Cervical Rib Excision",
    // Add more major surgeries here...
  ],
  "Minor Surgeries": [
    // You can add minor surgeries here...
  ],
};
const throatSurgeries = {
  "Major Surgeries": [
    "Adenotonsillectomy",
    "Endoscopic Adenoidectomy",
    "Coblation Adenoidectomy",
    "Coblation Tonsillectomy",
    "Tonsillectomy",
    "Tracheostomy",
    "Revision Tracheotomy",
    "Foreign Body Throat Removal",
    "Wound Debridement, Neck",
    "UPPP",
    "Coblation UPPP",
    "DL scopy +/- Biopsy (Direct laryngeoscopy)",
    "Rigid Oesophagoscopy",
    "Rigid Bronchoscopy",
    "Flexible Bronchoscopy",
    "Micro laryngeal Surgery (MLS)",
    "Laryngeal Microdebrider MLS",
    "Intralesional Cidofovir for vocal papilloma",
    "Tracheal Stenosis excision & T tube stenting",
    "Thyroglossal Duct Cyst Excision (Sistrunk)",
    "Total Thyroidectomy",
    "Retropharyngeal Abscess I&D",
    "Parotidectomy",
    "Submandibular gland surgery",
    "Laryngotracheal reconstruction (LTR)",
    "Thyroplasty surgery",
    "Vallecular cyst excision",
    "Cleft palate repair",
    "Oro-antral fistula repair",
    // Add more major surgeries here...
  ],
  "Minor Surgeries": [
    "Skin wart excision",
    "Neck Exploration & Sinus tract excision",
    "Intra-oral Excision & Biopsy",
    "Neck Node Biopsy",
    "Tongue Tie release",
    "Tongue CLW suturing",
    "Quinsy I&D",
    "Branchial Sinus Excision",
    "Flexible Laryngoscopy",
    "Flexible Nasal Laryngoscopy",
    "Laryngeal Telescopy",
    "Esophageal diverticulotomy",
    "FAB esophagus removal (Foreign Body oesophagus Removal)",
    "Tracheo – Esophageal Fistula (TFF) Repair",
    "Esophageal Atresia Repair",
    "Laryngomalacia repair",
    "Subglottic Stenosis",
    // Add more minor surgeries here...
  ],
};
const skullBaseSurgeries = {
  "Major Surgeries": [
    "Acoustic Neuroma Surgery",
    "Retrosigmoid Craniotomy and Endoscopic Excision of CP Angle Tumor",
    "Endoscopic Cavernous Sinus Surgery",
    "Anterior Cervical Discectomy",
    "Endoscopic Clival Lesion Surgery",
    "Endoscopic Craniopharyngeal Excision",
    "Endoscopic CSF Repair",
    "Endoscopic Hypophysectomy/Pituitary Surgery",
    "Microscopic Foramen Magnum Decompression (FMD)",
    "Endoscopic Anterior Cyst Excision",
    "Endoscopic Anterior Skull Base Repair",
    "Endoscopic Lateral Skull Base Repair",
    "Intratemporal Fossa Approach Surgery (FISCH Approach)",
    "Endolymphatic Sac Decompression Surgery",
    "Singular Neurectomy",
    "Vestibular Neurectomy",
    "Microvascular Decompression of Trigeminal Nerve",
    "Microvascular Decompression of Facial Nerve",
    "Endoscopic Clival Lesion/Tumor Surgery",
    "Endoscopic Transplanar Surgery",
    "Trans-oral Odontoid Fracture Fixation",
    "Cervical Laminoplasty",
    "Brainstem Implant Surgery",
    "Endoscopic Clival Chordoma Excision",
    "Endoscopic Petrous Apex Drainage/Biopsy",
    "Craniofacial Resection",
    "Superior Semicircular Canal Dehiscence Closure",
    "Trans Labyrinthine Excision of CP Angle Tumor/Vestibular Schwannoma",
    "Infra Temporal Fossa Approaches for Tumors",
    "Endoscopic Thornwaldt’s Cyst Excision",
    // Add more major surgeries here...
  ],
};

function ENT() {
  const [department, setDepartment] = useState("ENT");
  const navigate = useNavigate();
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentDropDownData, setDepartmentDropDownData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChangeDepartment = (event) => {
    const selectedValue = event.target.value;
    setDepartment(selectedValue);
    // Redirect to the selected department page
    navigate(`/departments/${selectedValue}`);
  };

  const handleDoctorProfileClick = (name) => {
    navigate(`/doctor/${name}`);
  };

  // const deptData = Departments.find((dept) => dept.department === department);

  useEffect(() => {
    // Fetches the data whenever 'department' changes
    const fetchSearchDepartmentApi = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/departments/search?Department=${department}`
        );
        setDepartmentData(response?.data?.data);
        const response2 = await axios.get(
          `${apiBaseUrl}/api/doctors/search?Department_ID=${response?.data?.data[0]?.SL_NO}`
        );
        setDoctorData(response2?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    const fetchDepartmentDataApi = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/departments/getAll`
        );
        setDepartmentDropDownData(response?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    fetchSearchDepartmentApi();
    fetchDepartmentDataApi();
  }, [department]);

  if (!departmentData) {
    // Handle the case when 'name' doesn't match any data
    return <div style={{ marginTop: "100px" }}>Department not found</div>;
  }

  const servicesData = departmentData[0]?.services;

  // Split the data into an array using the newline character '\n'
  const servicesArray = servicesData?.split("\n").filter(Boolean);

  const facilitiesData = departmentData[0]?.facilities;

  // Split the data into an array using the newline character '\n'
  const facilitiesArray = facilitiesData?.split("\n").filter(Boolean);
  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        marginTop: "100px",
      }}
      className={styles.DepartmentContainer}>
      <Grid container columnGap={12}>
        <Grid item xs={12} md={4} marginTop={"30px"}>
          <Typography fontSize={"35px"} fontFamily={"Inter"} fontWeight={"400"}>
            ENT
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} marginTop={"30px"}>
          <FormControl
            fullWidth
            sx={{
              height: "82px",
              "& .MuiSelect-root": { backgroundColor: "transparent" },
            }}>
            <InputLabel>Department</InputLabel>
            <Select
              value={department}
              label="Department"
              onChange={handleChangeDepartment}>
              {departmentDropDownData.map(({ Department }, index) => (
                <MenuItem value={Department}>{Department}</MenuItem>
              ))}

              {/* <MenuItem value="General Medicine">General Medicine</MenuItem> */}
              {/* <MenuItem value="Dentals">Dentals</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columnGap={12} marginTop={"30px"}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Grid container>
            <Grid item xs={12}>
              <img
                width={"100%"}
                src={`${imageBaseUrl}/${departmentData[0]?.SL_NO}.jpg`}
                onError={(e) => (e.target.src = DepartmentImage_1)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                textAlign={"justify"}
                marginTop={"30px"}>
                Internists strive to provide compassionate care of the highest
                quality to their patients while promoting healthy lifestyles and
                disease prevention. Adult Immunization- Besides routine
                examination and diagnosis of diseases followed by investigation
                and treatment, all types of fever and infections, are also
                managed by the department. Specialized tests, use of rapid
                bacterial culture and sensitivity techniques, PCR and other
                investigation modalities provides the best quality support for
                these.
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="22px"
                fontFamily="Inter"
                fontWeight="600">
                Ear
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="500">
                Surgeries performed: (Major Surgeries)
              </Typography>
              <ul>
                {surgeries["Major Surgeries"].map((majorsurgery, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {majorsurgery}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="500">
                Minor Surgeries
              </Typography>
              <ul>
                {surgeries["Minor Surgeries"].map((minorsurgery, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {minorsurgery}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                color="#211E1E"
                fontSize="22px"
                fontFamily="Inter"
                fontWeight="600">
                Nose
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="500">
                Major Surgeries
              </Typography>
              <ul>
                {noseSurgeries["Major Surgeries"].map((majorsurgery, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {majorsurgery}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="500">
                Minor Surgeries
              </Typography>
              <ul>
                {noseSurgeries["Minor Surgeries"].map((minorsurgery, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {minorsurgery}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                color="#211E1E"
                fontSize="22px"
                fontFamily="Inter"
                fontWeight="600">
                THROAT
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="500">
                Major Surgeries
              </Typography>
              <ul>
                {throatSurgeries["Major Surgeries"].map(
                  (majorsurgery, index) => (
                    <li key={index}>
                      <Typography
                        color="#211E1E"
                        fontSize="18px"
                        fontFamily="Inter"
                        fontWeight="400">
                        {majorsurgery}
                      </Typography>
                    </li>
                  )
                )}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="500">
                Minor Surgeries
              </Typography>
              <ul>
                {throatSurgeries["Minor Surgeries"].map(
                  (minorsurgery, index) => (
                    <li key={index}>
                      <Typography
                        color="#211E1E"
                        fontSize="18px"
                        fontFamily="Inter"
                        fontWeight="400">
                        {minorsurgery}
                      </Typography>
                    </li>
                  )
                )}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="22px"
                fontFamily="Inter"
                fontWeight="600">
                Head and Neck Surgery
              </Typography>
              <ul>
                {headAndNeckSurgeries["Major Surgeries"].map(
                  (majorsurgery, index) => (
                    <li key={index}>
                      <Typography
                        color="#211E1E"
                        fontSize="18px"
                        fontFamily="Inter"
                        fontWeight="400">
                        {majorsurgery}
                      </Typography>
                    </li>
                  )
                )}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="22px"
                fontFamily="Inter"
                fontWeight="600">
                Skull base Surgery
              </Typography>
              <ul>
                {skullBaseSurgeries["Major Surgeries"].map(
                  (majorsurgery, index) => (
                    <li key={index}>
                      <Typography
                        color="#211E1E"
                        fontSize="18px"
                        fontFamily="Inter"
                        fontWeight="400">
                        {majorsurgery}
                      </Typography>
                    </li>
                  )
                )}
              </ul>
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                marginBottom={"30px"}
                textAlign={"justify"}
                marginTop={"30px"}></Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                textAlign={"justify"}></Typography>
            </Grid> */}
          </Grid>
          {/* <Grid container marginTop={"30px"}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="24px"
                fontFamily="Inter"
                fontWeight="600">
                Services
              </Typography>
              <ul>
                {servicesArray?.map((service, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {service}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="24px"
                fontFamily="Inter"
                fontWeight="600">
                Facilities
              </Typography>
              <ul>
                {facilitiesArray?.map((facility, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {facility}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography
            marginTop="110px"
            fontFamily="Inter"
            fontSize="35px"
            fontWeight="700"
            letterSpacing="0.7px"
            lineHeight="133.523%"
            textAlign="center">
            Meet Our Team
          </Typography>
          <Grid
            container
            spacing={5}
            display="flex"
            justifyContent="center"
            sx={{ marginTop: "10px", marginBottom: "36px" }}>
            {doctorData.map(({ GG_ID, Name }, index) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={GG_ID}>
                <Grid container>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <HoverImage
                      image={`${doctorsImagePath}/${GG_ID}.jpg`}
                      width={"320px"}
                      height={"350px"}
                      btnName={"View Profile"}
                      objectFit="cover"
                      onButtonClick={() => handleDoctorProfileClick(Name)}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="1.8rem"
                      fontStyle="normal"
                      fontWeight="600"
                      letterSpacing="0.6px"
                      lineHeight="133.523%"
                      textAlign="center">
                      {Name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="20px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="133.523%"
                      textAlign="center"
                      sx={{ opacity: "0.5" }}>
                      {department}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ENT;
