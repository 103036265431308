import React from "react";
import { useState, useRef, useEffect } from "react";

//mui imports
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

import styles from "./NormalSwiper.module.css";

//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay } from "swiper/modules";

//department images
import DepartmentImage_1 from "../../assets/images/homedepart1.png";
import DepartmentImage_2 from "../../assets/images/homedepart2.png";
import DepartmentImage_3 from "../../assets/images/homedepart3.png";
import FacilityImage_1 from "../../assets/images/facilities/Image1.jpg";
import FacilityImage_2 from "../../assets/images/facilities/Image2.jpg";
import FacilityImage_3 from "../../assets/images/facilities/Image3.jpg";
import FacilityImage_4 from "../../assets/images/facilities/Image4.jpg";
import FacilityImage_5 from "../../assets/images/facilities/Image5.jpg";

const Testimonials = [
  {
    id: 1,

    image: FacilityImage_1,
  },
  {
    id: 2,

    image: FacilityImage_2,
  },
  {
    id: 3,

    image: FacilityImage_3,
  },
  {
    id: 4,

    image: FacilityImage_4,
  },
  {
    id: 5,

    image: FacilityImage_5,
  },
];
function NormalSwiper({ swiperRef }) {
  // const swiperRef = useRef(null);

  // const handlePrevious = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev();
  //   }
  // };

  // const handleNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext();
  //   }
  // };

  const [slidesPerView, setSlidesPerView] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 700) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {" "}
      <div className={styles.carouselWrapper}>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={slidesPerView}
          spaceBetween={160}
          navigation={{
            prevEl: ".custom-prev",
            nextEl: ".custom-next",
          }}
          centeredSlides={true}
          loop={true}
          autoplay={{ delay: 3000 }}
          // pagination={{ clickable: true }}
          className={styles.carouselWrapper}>
          {Testimonials.map(({ id, image }, index) => (
            <SwiperSlide className={styles.slide} key={id}>
              <div className={styles.whyMsigmaCarouselCard}>
                <Grid container>
                  {" "}
                  <Grid item xs={12}>
                    <img src={image} alt="" className={styles.SwiperImage} />
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default NormalSwiper;
