import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./home.module.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { Container, Typography } from "@mui/material";
const adPath = "/ads";

function AdsPopup({ onclose }) {
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/departments/Medical%20Oncology");
  };

  const addButtonClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // setOpen(false); // Close the modal
    if (onclose) {
      onclose();
    }
  };
  return (
    <Container component="main" maxWidth="300px">
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: "10px",
            backgroundColor: "#9c008f",
          }}
          container>
          <Typography
            variant="h4"
            sx={{ paddingLeft: "10px" }}
            fontSize="22px"
            color="#ffffff">
            Campaign Notification
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* <Divider sx={{ my: 1 }} /> */}
        <DialogContent
          onClick={handleRedirect}
          sx={{
            backgroundColor: "#f1dfef",
          }}>
          <Grid container spacing={3}>
            <Grid item>
              <img
                src={`${adPath}/ad.jpg`}
                alt=""
                className={styles.mainImage}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default AdsPopup;
