import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//style
import styles from "./About.module.css";

//local components
import HoverImage from "../../components/HoverImage/HoverImage";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SouthIcon from "@mui/icons-material/South";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

//images
import nabh from "../../assets/images/nabh.png";
import nabl from "../../assets/images/nabl.png";

import kayakalp from "../../assets/images/kayakalp.png";
import epihc from "../../assets/images/epihc.png";
import chairman from "../../assets/images/chairman.png";
import vicechairman from "../../assets/images/vicechairman.png";
import md from "../../assets/images/md.png";
import GoogleMaps from "../../components/GoogleMaps/GoogleMaps";

function About() {
  const Administration = [
    {
      id: 1,
      name: "Sri Gokulam Gopalan",
      image: chairman,
      title: "Chairman",
    },
    {
      id: 2,
      name: "Dr. K K Manojan",
      image: vicechairman,
      title: "Vice Chairman",
    },
    {
      id: 3,
      name: "Dr. Sheeja G Manoj",
      image: md,
      title: "Managing Director",
    },
  ];

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const navigate = useNavigate();
  const handleProfileClick = (name) => {
    navigate(`/administration/${name}`);
  };
  return (
    <Container component="main" maxWidth={false} className={styles.container}>
      <Grid container className={styles.welcomeSect}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography
            fontWeight="400"
            fontSize="4rem"
            className={styles.welcomeSectLeft}>
            Welcome to<br></br>
            <span style={{ fontWeight: "bold" }}>GG Hospital</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="195%"
            letterSpacing="0.36px"
            textAlign={"justify"}>
            GG Hospital is much more than a Hospital…. It’s a Healing Home…A
            Healthier Life Restarts from GG… GG Hospital is the flagship
            hospital of the Sree Gokulam Group of Companies. Our journey started
            on 27th January 2016 for the common people in Trivandrum. The
            History made by the GG Hospital in the life of common people begins
            in 2016 and continues, which is remarkable to date. It has been
            achieved through a combination of carefully chosen technology
            acquisitions and undoubtedly the result of hard work and consistent
            delivery of excellent services by our staff members. In the medical
            arena, our success is ultimately based on the medically indicated
            quality diagnostic practices that have remained true to the ethical
            principles of the medical profession.
          </Typography>
          <Typography
            marginBottom="112px"
            variant="subtitle1"
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="195%"
            letterSpacing="0.36px"
            textAlign={"justify"}>
            GG Hospital is one of the fastest-growing multi-super speciality
            hospitals in South Kerala. The patient-friendly facilities at GG
            Hospital will ensure to deliver the exceptional quality of patient
            care. The facilities are designed and equipped to meet the
            requirements of both national and international patients. The
            patient rooms are designed with all modern comforts. Patients and
            family members experience a serene environment that offers them
            privacy and space, giving them a new experience in the healthcare
            industry."
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={styles.aboutBanner}>
        {/* <Grid item xs={12} sm={4} className={styles.aboutBannerLogo}></Grid> */}
      </Grid>
      <Grid container className={styles.aboutSect}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography
            marginBottom="20px"
            fontFamily="Inter"
            fontSize="28px"
            fontWeight="600"
            letterSpacing="0.56px">
            Our Vision
          </Typography>
          <Typography
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="180%"
            marginBottom="80px">
            <span style={{ fontWeight: "bold" }}>
              “Our Passion is Quality Care”.
            </span>{" "}
            We serve the common man with the highest standards of health care to
            save million lives.
          </Typography>
          <Typography
            marginBottom="20px"
            fontFamily="Inter"
            fontSize="28px"
            fontWeight="600"
            letterSpacing="0.56px">
            Our Mission
          </Typography>
          <Typography
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="180%"
            marginBottom="20px">
            Our Mission is to deliver outstanding clinical healthcare services
            reliably, safely and qualitatively in an advanced and cost-effective
            way.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<SouthIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="180%">
                Our Service Standards
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                fontFamily="Inter"
                fontSize="18px"
                fontWeight="400"
                lineHeight="180%">
                Our service standards describe the heights of service we aim to
                deliver. As a corporate hospital serves common man, we will:
                <ul>
                  <li>Work as a professional clinical service provider</li>
                  <li>Treat you with courtesy and respect</li>
                  <li>Clear accurate and timely health care information</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{ boxShadow: "none", borderTop: "1px solid #BDBDBD" }}>
            <AccordionSummary
              expandIcon={<SouthIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="180%">
                Values
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                fontFamily="Inter"
                fontSize="18px"
                fontWeight="400"
                lineHeight="180%">
                <ul>
                  <li>
                    Kind and Dedicated Care – to our patients and families
                  </li>
                  <li>Ownership- for achieving excellent successful results</li>
                  <li>
                    Collaboration – work across the Gokulam Group of Hospitals
                    to achieve a common rationale.
                  </li>
                  <li>
                    Innovation and Empowerment- Mend, Modify or Make ideas to
                    affect positive outcomes
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{ boxShadow: "none", borderTop: "1px solid #BDBDBD" }}>
            <AccordionSummary
              expandIcon={<SouthIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="180%">
                Quality Objectives
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                fontFamily="Inter"
                fontSize="18px"
                fontWeight="400"
                lineHeight="180%">
                <ul>
                  <li>To become a well-governed and adaptable organization.</li>
                  <li>
                    To be a patient-centred organization providing high quality,
                    kind care with integrity.
                  </li>
                  <li>
                    To deliver the benefits of advanced medical technology to
                    the common man.
                  </li>
                  <li>
                    To achieve compliance with national/international standards.
                  </li>
                  <li>
                    To empower and involve all employees in continuous quality
                    improvement programs to achieve patient satisfaction.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{ boxShadow: "none", borderTop: "1px solid #BDBDBD" }}>
            <AccordionSummary
              expandIcon={<SouthIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="180%">
                Quality Policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                fontFamily="Inter"
                fontSize="18px"
                fontWeight="400"
                lineHeight="180%">
                GG Hospitals shall:- <br></br>
                <br></br> Deliver reliable quality medical services through
                constant improvement, with technical expertise and safety that
                is extremely effective with the motto of “love and care to
                humanity”
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            marginTop="110px"
            fontFamily="Inter"
            fontSize="35px"
            fontWeight="700"
            letterSpacing="0.7px"
            lineHeight="133.523%"
            textAlign="center">
            Administration
          </Typography>
          <Grid
            container
            spacing={5}
            display="flex"
            justifyContent="center"
            sx={{ marginTop: "10px", marginBottom: "36px" }}>
            {Administration.map(({ id, name, image, title }, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                <Grid container>
                  <Grid item xs={12}>
                    <HoverImage
                      image={image}
                      width={"100%"}
                      height={"auto"}
                      btnName={"View Profile"}
                      onButtonClick={() => handleProfileClick(name)}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="1.8rem"
                      fontStyle="normal"
                      fontWeight="600"
                      letterSpacing="0.6px"
                      lineHeight="133.523%"
                      textAlign="center">
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="20px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="133.523%"
                      textAlign="center"
                      sx={{ opacity: "0.5" }}>
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Typography
        display="flex"
        justifyContent="center"
        marginTop="110px"
        marginBottom="30px"
        fontFamily="Inter"
        fontSize="35px"
        fontWeight="700"
        letterSpacing="0.7px"
        lineHeight="133.523%"
        textAlign="center">
        Accreditation
      </Typography>
      <Grid container spacing={5} className={styles.accSect}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={styles.accCardImage}>
            <img src={nabl} alt="" />
          </Card>
          <Typography
            display="flex"
            justifyContent="center"
            marginTop="37px"
            fontFamily="Inter"
            fontSize="29px"
            fontWeight="600"
            lineHeight="133.523%"
            textAlign="center">
            NABL
          </Typography>
          {/* <Typography
            display="flex"
            justifyContent="center"
            color="#9D9C9C"
            marginTop="7px"
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="400"
            lineHeight="133.523%"
            textAlign="center">
            21 April 2020 - 20 April 2023
          </Typography> */}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={styles.accCardImage}>
            <img src={nabh} alt="" />
          </Card>
          <Typography
            display="flex"
            justifyContent="center"
            marginTop="37px"
            fontFamily="Inter"
            fontSize="29px"
            fontWeight="600"
            lineHeight="133.523%"
            textAlign="center">
            NABH
          </Typography>
          {/* <Typography
            display="flex"
            justifyContent="center"
            color="#9D9C9C"
            marginTop="7px"
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="400"
            lineHeight="133.523%"
            textAlign="center">
            21 April 2020 - 20 April 2023
          </Typography> */}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={styles.accCardImage}>
            <img src={kayakalp} alt="" />
          </Card>
          <Typography
            display="flex"
            justifyContent="center"
            marginTop="37px"
            fontFamily="Inter"
            fontSize="29px"
            fontWeight="600"
            lineHeight="133.523%"
            textAlign="center">
            Kayakalp
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={styles.accCardImage}>
            <img src={epihc} alt="" width={"100%"} />
          </Card>
          <Typography
            display="flex"
            justifyContent="center"
            marginTop="37px"
            fontFamily="Inter"
            fontSize="29px"
            fontWeight="600"
            lineHeight="133.523%"
            textAlign="center">
            Epihc
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid container marginTop="200px" marginBottom="80px" width="90%">
        <Grid item xs={12} display="flex" justifyContent="center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.8308936467556!2d76.93071707568835!3d8.515794296809798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bbe4a0f90d19%3A0x6e5d31275c5199f3!2sGG%20Hospital!5e0!3m2!1sen!2sin!4v1689319597778!5m2!1sen!2sin"
            width="90%"
            height="472"
          ></iframe>
        </Grid>
      </Grid> */}
      <div className={styles.googleMap}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.8308936467556!2d76.93071707568835!3d8.515794296809798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bbe4a0f90d19%3A0x6e5d31275c5199f3!2sGG%20Hospital!5e0!3m2!1sen!2sin!4v1689319597778!5m2!1sen!2sin"
          width="80%"
          height="472"
          style={{ border: "none" }}></iframe>
      </div>
    </Container>
  );
}

export default About;
