import React from "react";
import { Link } from "react-router-dom";

//style
import styles from "./Footer.module.css";
import gglogo from "../../assets/images/gglogo.png";
import fb from "../../assets/images/fb.png";
import ig from "../../assets/images/ig.png";
import yt from "../../assets/images/yt.png";
import lin from "../../assets/images/in.png";
import home from "../../assets/images/home.svg";
import phone from "../../assets/images/phone.svg";
import mail from "../../assets/images/mail.svg";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

function Footer() {
  return (
    <footer className={styles.footer}>
      <Container maxWidth="xl">
        <Grid
          container
          padding={"30px"}
          paddingTop={"100px"}
          paddingBottom={"50px"}
          alignItems={"flex-end"}>
          <Grid item sm={12} md={5}>
            <Grid container flexDirection={"column"}>
              <Grid item xs={12} md={8} marginBottom={"-10px"}>
                <img
                  src={gglogo}
                  width="250px"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  fontFamily="Inter"
                  fontSize="14px"
                  color="black"
                  fontWeight="400"
                  lineHeight="194.5%"
                  textAlign="left"
                  marginTop="20px"
                  style={{ opacity: 0.699999988079071 }}>
                  GG Hospital (Paragon Hospital Kerala Pvt. Ltd) is the flagship
                  hospital of the Sree Gokulam Group of Companies.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={7}>
            <Grid container>
              <Grid item sm={12} md={12} lg={6} width={"100%"}>
                <Grid container>
                  <Grid item xs={12} marginTop={"50px"}>
                    <Typography
                      fontSize="12"
                      fontWeight="bold"
                      fontFamily="Inter">
                      About GG
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"30px"}
                      fontFamily="Inter">
                      <Link
                        to="/about"
                        style={{
                          color: "#000",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}>
                        About Us
                      </Link>
                    </Typography>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"28px"}
                      fontFamily="Inter">
                      <Link
                        to="/"
                        style={{
                          color: "#000",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}>
                        Hostpital
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"30px"}
                      fontFamily="Inter">
                      <Link
                        to="/academicsandresearch"
                        style={{
                          color: "#000",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}>
                        Academic
                      </Link>
                    </Typography>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"28px"}
                      fontFamily="Inter">
                      <Link
                        to="/departments"
                        style={{
                          color: "#000",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}>
                        Department
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={12} lg={6} width={"100%"}>
                <Grid container>
                  <Grid item xs={12} marginTop={"50px"}>
                    <Typography
                      fontSize="12"
                      fontWeight="bold"
                      fontFamily="Inter">
                      Legal
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"30px"}
                      fontFamily="Inter">
                      <Link
                        to="/privacypolicy"
                        style={{
                          color: "#000",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}>
                        Privacy Policy
                      </Link>
                    </Typography>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"28px"}
                      fontFamily="Inter">
                      Documentation
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"30px"}
                      fontFamily="Inter">
                      Terms of Services
                    </Typography>
                    <Typography
                      fontSize="15px"
                      color="black"
                      marginTop={"28px"}
                      fontFamily="Inter">
                      Site Map
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3} padding={"30px"}>
          <Grid item xs={12} sm={12} md={3} width={"100%"}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <img src={home} alt="" style={{ cursor: "pointer" }} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  paddingTop="5px"
                  lineHeight="180%"
                  fontFamily="Inter"
                  fontSize="14px">
                  Medical College, Pattom Kumarapuram Rd, Murinjapalam,
                  Thiruvananthapuram, Kerala 695011
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={1}></Grid>
          <Grid item xs={12} sm={6} md={2} width={"100%"}>
            <Grid container style={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} lg={12}>
                <img src={phone} alt="" style={{ cursor: "pointer" }} />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography
                  paddingTop="5px"
                  lineHeight="180%"
                  fontFamily="Inter"
                  fontSize="14px">
                  0471 2779100
                </Typography>
                <Typography
                  lineHeight="180%"
                  fontFamily="Inter"
                  fontSize="14px">
                  0471 2779101
                </Typography>
                <Typography
                  lineHeight="180%"
                  fontFamily="Inter"
                  fontSize="14px">
                  0471 4299299
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} width={"100%"}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <img src={mail} alt="" style={{ cursor: "pointer" }} />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography
                  paddingTop="5px"
                  lineHeight="180%"
                  fontFamily="Inter"
                  fontSize="14px">
                  gghospitalphkpl@gmail.com
                </Typography>
                <Typography
                  lineHeight="180%"
                  fontFamily="Inter"
                  fontSize="14px">
                  mdoffice@gg-hospital.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} width={"100%"}>
            <Grid container rowGap={1}>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Typography
                  fontSize="21px"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="188.5%">
                  We are on Social Media
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Link
                  to="https://www.linkedin.com/company/sreegokulamgghospital/"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  <img
                    src={lin}
                    alt=""
                    style={{ cursor: "pointer", marginRight: "30px" }}
                  />
                </Link>

                <Link
                  to="https://www.youtube.com/channel/UCtCjvDYpiZc46Kj_ZsdBTzg"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  <img
                    src={yt}
                    alt=""
                    style={{ cursor: "pointer", marginRight: "30px" }}
                  />
                </Link>

                <Link
                  to="https://www.instagram.com/gg_hospital/"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  <img
                    src={ig}
                    alt=""
                    style={{ cursor: "pointer", marginRight: "30px" }}
                  />
                </Link>
                <Link
                  to="https://www.facebook.com/gghospitaltvm/"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}>
                  <img src={fb} alt="" style={{ cursor: "pointer" }} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid Container rowGap={12} marginTop={"20px"} marginBottom={"20px"}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography
              marginTop={"28px"}
              color="#000"
              textAlign="center"
              fontFamily="Inter"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="188.5%">
              © 2023 GG Hospital.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
