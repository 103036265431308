import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import MySwiper from "../../components/MySwiper/MySwiper";
import Playbutton from "../../assets/images/playbutton.png";
import Greentick from "../../assets/images/greenticklogo.png";
import NormalSwiper from "../../components/NormalSwiper/NormalSwiper";

import sendEmail from "../../utils/Email";

import styles from "./home.module.css";

//mui components
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import { LiaLongArrowAltLeftSolid } from "react-icons/lia";

//images
import Doctor_1 from "../../assets/images/doctor1.png";
import WhyGgBackground from "../../assets/images/whyggdoctorimage.png";
import HomeImage from "../../assets/images/HomeImageCropped.png";

//department images
import DepartmentImage_1 from "../../assets/images/homedepart1.png";
import DepartmentImage_2 from "../../assets/images/homedepart2.png";
import DepartmentImage_3 from "../../assets/images/homedepart3.png";
import AdsPopup from "./AdsPopup";
import SocialMediaEmbeded from "../../components/SocailMediaEmbeded/SocialMediaEmbeded";

const imageBaseUrl = "/doctors";

const whygg = [
  {
    text: "Fastest growing multi-super specialty hospital in South Kerala",
  },
  {
    text: "Achieved the unique reputation of attracting the finest clinical talent from across the country",
  },
  {
    text: "The International Patient Services at GG Hospital ensure to strike the chord of care in every patient from the moment they establish contact till the time they are discharged after treatment and cure",
  },
  {
    text: "The growth of GG Hospital to become such a reputed and renowned institution in Kerala has been fueled by the combination of Love, Care and Quality",
  },
  {
    text: "GG hospital emphasises Love and care towards humanity provided to patients and thus got accredited by NABH and NABL from 2020 onwards.",
  },
];

const Specialists = [
  {
    id: 1,
    name: "Lisha Powell",
    image: Doctor_1,
    profession: "ENT Specialists",
  },
  {
    id: 2,
    name: "Lisha Powell",
    image: Doctor_1,
    profession: "ENT Specialists",
  },
  {
    id: 3,
    name: "Lisha Powell",
    image: Doctor_1,
    profession: "ENT Specialists",
  },
  {
    id: 3,
    name: "Lisha Powell",
    image: Doctor_1,
    profession: "ENT Specialists",
  },
];
const Departments = [
  {
    id: 1,
    name: "Cardiology",
    image: DepartmentImage_1,
    review:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
  {
    id: 2,
    name: "Medical Oncology",
    image: DepartmentImage_2,
    review:
      "GG Hospital is the best affordable hospital in South Kerala which is NABH accredited hospital...",
  },
  {
    id: 3,
    name: "ENT, Head & Neck Skull Surgery",
    image: DepartmentImage_3,
    review:
      "The ENT Department in GG Hospital provides a wide range of treatment modalities including...",
  },
  {
    id: 4,
    name: "ENT, Head & Neck Skull Surgery",
    image: DepartmentImage_1,
    review:
      "The ENT Department in GG Hospital provides a wide range of treatment modalities including...",
  },
  {
    id: 5,
    name: "Medical Oncology",
    image: DepartmentImage_2,
    review:
      "The ENT Department in GG Hospital provides a wide range of treatment modalities including...",
  },
];
const Interested = [
  {
    id: 1,
    name: "Hepatobiliary Surgery",
    image: DepartmentImage_1,
    review:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
  {
    id: 2,
    name: "Medical Oncology",
    image: DepartmentImage_2,
    review:
      "GG Hospital is the best affordable hospital in South Kerala which is NABH accredited hospital...",
  },
  {
    id: 3,
    name: "ENT, Head & Neck Skull Surgery",
    image: DepartmentImage_3,
    review:
      "The ENT Department in GG Hospital provides a wide range of treatment modalities including...",
  },
  {
    id: 4,
    name: "ENT, Head & Neck Skull Surgery",
    image: DepartmentImage_1,
    review:
      "The ENT Department in GG Hospital provides a wide range of treatment modalities including...",
  },
  {
    id: 5,
    name: "Medical Oncology",
    image: DepartmentImage_2,
    review:
      "The ENT Department in GG Hospital provides a wide range of treatment modalities including...",
  },
];

function Home() {
  const swiperRef = useRef(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [specialist, setSpecialist] = useState([]);
  //appointment_booking start

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const FORMSPARK_ACTION_URL = `${apiBaseUrl}/api/masters/bookAppointment`;
  const initialState = {
    fullName: "",
    number: "",
    date: "",
    department: "",
    doctor: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [openPopup, setOpenPopup] = useState(true);

  const addButtonClick = () => {
    setOpenPopup(true);
  };

  const closePopup = () => {
    setOpenPopup(false);
  };

  //on value change set data
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //validation of data
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) {
      newErrors.fullName = "Full Name is required";
    }
    if (!formData.number) {
      newErrors.number = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.number)) {
      newErrors.number = "Phone Number must be exactly 10 digits";
    }
    if (!formData.date) {
      newErrors.date = "Date is required";
    }
    if (!formData.department) {
      newErrors.department = "Department is required";
    }
    if (!formData.doctor) {
      newErrors.doctor = "Doctor is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit API call
  async function submitBooking(formData) {
    await sendEmail(formData, "New Booking on GG Website"); //TODO error&loading
    return await fetch(FORMSPARK_ACTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });
  }

  // Handling success
  function handleSuccess(data) {
    if (data?.status === 403) {
      toast.error(data?.message);
      setFormData(initialState);
      setErrors({});
    } else {
      toast.success(data?.message);
      setFormData(initialState);
      setErrors({});
    }
  }

  // Handling error
  function handleError() {
    toast.error("An error occurred. Please try again later.");
  }

  const handleBookAppointment = async () => {
    if (validateForm()) {
      try {
        formData.department = departmentData.find(
          (dept) => dept.SL_NO === formData.department
        ).Department;

        const response = await submitBooking(formData);
        if (response.ok) {
          const data = await response.json();
          handleSuccess(data);
        } else {
          handleError();
        }
        // toast.success("Appointment booked successfully!");
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Appointment not booked!");
      }
    }
  };

  //appointment_booking end

  const handlePrevious = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const normalswiperRef = useRef(null);

  const NormalSwiperhandlePrevious = () => {
    if (normalswiperRef.current && normalswiperRef.current.swiper) {
      normalswiperRef.current.swiper.slidePrev();
    }
  };

  const NormalSwiperhandleNext = () => {
    if (normalswiperRef.current && normalswiperRef.current.swiper) {
      normalswiperRef.current.swiper.slideNext();
    }
  };

  const interestedswiperRef = useRef(null);

  const InterestedSwiperhandlePrevious = () => {
    if (interestedswiperRef.current && interestedswiperRef.current.swiper) {
      interestedswiperRef.current.swiper.slidePrev();
    }
  };

  const InterestedSwiperhandleNext = () => {
    if (interestedswiperRef.current && interestedswiperRef.current.swiper) {
      interestedswiperRef.current.swiper.slideNext();
    }
  };
  const navigate = useNavigate();
  const handleBtnClick = () => {
    navigate("/doctor'stalk");
  };

  const fetchDepartmentDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/departments/getAll`);
      setDepartmentData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  const fetchDoctorDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/doctors/getAll`);
      setSpecialist(response?.data?.data);
      setDoctorData(response?.data?.data.slice(0, 8));
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchDepartmentDataApi();
    fetchDoctorDataApi();
  }, []);

  const handleDepartmentReadmoreClick = (data) => {
    navigate(`/departments/${data.Department}`);
  };

  const handleSpecialistViewAllClick = () => {
    navigate(`/patientcare/`);
  };

  const handleAppointmentBtnClick = () => {
    navigate(`/bookappointment`);
  };

  const selectedDoctor = specialist.find(
    (doctor) => doctor.Name === formData.doctor
  );
  console.log(doctorData);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container
        component="main"
        maxWidth={false}
        // style={{ width: "90%" }}
        className={styles.HomeContainer}>
        {/* start first-section */}
        <Grid container className={styles.HomeSection}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={styles.HomeImageFirstSession}
            alignItems="center"
            order={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <Grid item spacing={2}>
                {" "}
                <Typography
                  variant="h2"
                  component="h2"
                  className={styles.HeadingTypography}>
                  Love & Care to <br></br> Humanity
                </Typography>
                {/* <Typography variant="h2" className={styles.HeadingTypography}>
                  Humanity
                </Typography> */}
                <Typography
                  variant="subtitle1"
                  className={styles.SubHeadingTypography}>
                  Fastest-Growing Multi Speciality Hospital
                </Typography>
                <Typography variant="subtitle1">in South Kerala</Typography>
                <Grid container sx={{ marginTop: "10px" }} spacing={3}>
                  {" "}
                  <Grid item sx={6}>
                    <Button
                      variant="contained"
                      size="large"
                      className={styles.BookAppoinmentButton}
                      sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                      }}
                      onClick={handleAppointmentBtnClick}>
                      Book an Appointment
                    </Button>
                    {/* Toast notifications */}
                    <Toaster position="top-right" reverseOrder={false} />
                  </Grid>
                  <Grid item sx={6}>
                    <Button size="large" onClick={handleBtnClick}>
                      {" "}
                      <img src={Playbutton} alt="" />
                      <Typography className={styles.DotctorsTalk}>
                        Doctors Talk
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={styles.HomeImageSecondSession}
            order={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
            <img src={HomeImage} alt="" className={styles.HomeImage} />
          </Grid>
        </Grid>
        {/* end first-section */}

        {/* start book appointment section */}
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          className={styles.bookAppoinmentSection}>
          <Grid
            container
            sx={{
              boxShadow: "0px 14px 23px 0px rgba(0, 0, 0, 0.03)",
              marginTop: "36px",
            }}
            className={styles.bookAppoinmentcard}>
            <Grid item xs={12}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="20px"
                fontStyle="normal"
                fontWeight="600"
                marginBottom="10px">
                Book an Appointment
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    error={!!errors.fullName}
                    helperText={errors.fullName}
                    sx={{
                      // width: "350px",
                      height: "77px",
                      "& input": { backgroundColor: "transparent" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                    error={!!errors.number}
                    helperText={errors.number}
                    sx={{
                      // width: "350px",
                      height: "77px",
                      "& input": { backgroundColor: "transparent" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <FormControl
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "82px",
                      "& .MuiSelect-root": { backgroundColor: "transparent" },
                    }}>
                    <InputLabel> Department</InputLabel>
                    <Select
                      name="department"
                      value={formData.department}
                      onChange={handleChange}
                      error={!!errors.department}
                      label="Department">
                      {departmentData.map((dept) => (
                        <MenuItem value={dept.SL_NO}>
                          {dept.Department}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.department && (
                      <Typography color="error">{errors.department}</Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={12} sx={{ marginBottom: "10px" }}>
              {" "}
              {selectedDoctor &&
                selectedDoctor.Timing &&
                selectedDoctor.Timing.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ marginTop: 1 }}>
                      Doctor Timing: {selectedDoctor.Timing}
                    </Typography>
                  </Grid>
                )}
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <FormControl
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "82px",
                    "& .MuiSelect-root": { backgroundColor: "transparent" },
                  }}>
                  <InputLabel>Doctor</InputLabel>
                  <Select
                    name="doctor"
                    value={formData.doctor}
                    onChange={handleChange}
                    error={!!errors.doctor}
                    label="Doctor">
                    {specialist
                      .filter((doctor) => {
                        // console.log(doctor.Department_ID);
                        return doctor.Department_ID === formData.department;
                      })
                      .map((doctor) => {
                        // console.log(doctor);
                        return (
                          <MenuItem key={doctor.id} value={doctor.Name}>
                            {doctor.Name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors.doctor && (
                    <Typography color="error">{errors.doctor}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={4}>
                <TextField
                  variant="outlined"
                  label="Date"
                  fullWidth
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  error={!!errors.date}
                  helperText={errors.date}
                  sx={{
                    // width: "350px",
                    height: "77px",
                    "& input": { backgroundColor: "transparent" },
                  }}
                  InputLabelProps={{
                    shrink: true, // Prevent the label from collapsing
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Button
                  variant="contained"
                  size="large"
                  className={styles.BookAppoinmentCardButton}
                  onClick={handleBookAppointment}
                  sx={{
                    textTransform: "none",
                    borderRadius: "6px",
                  }}>
                  Book an Appointment
                </Button>
                <Toaster position="top-right" reverseOrder={false} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* end book appointment section */}

        {/* start our department-section */}
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "150px",
              }}>
              <Grid item>
                <Typography
                  className={styles.DepartmentTypography}
                  fontFamily="Inter"
                  fontWeight="700"
                  fontSize="35px">
                  Our <br></br>Departments
                </Typography>
              </Grid>
              <Grid item>
                <LiaLongArrowAltLeftSolid
                  size={30}
                  onClick={handlePrevious}></LiaLongArrowAltLeftSolid>
                <LiaLongArrowAltRightSolid
                  size={30}
                  onClick={handleNext}
                  style={{ width: "50px" }}></LiaLongArrowAltRightSolid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            {" "}
            <MySwiper
              departments={departmentData}
              swiperRef={swiperRef}
              onClickReadmore={handleDepartmentReadmoreClick}
            />
          </Grid>
        </Grid>
        {/* end our department-section */}

        {/* start why gg hospital-section */}
        <Grid container className={styles.WhyGgHospital}>
          <Grid item xs={12} lg={6} className={styles.WhyGgFirstPart}>
            <img
              src={WhyGgBackground}
              alt=""
              className={styles.WhyGgBackgroundImage}
              style={{}}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className={styles.WhyGgSecondPart}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <div>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="35px"
                fontWeight="700"
                marginTop="50px"
                letterSpacing="0.75px">
                Why GG Hospital
              </Typography>
              {whygg.map((item, index) => (
                <Grid
                  style={{
                    display: "flex",
                    marginTop: "40px",
                    marginBottom: "40px",
                    wordWrap: "break-word",
                  }}>
                  <img src={Greentick} alt="" className={styles.Greentick} />

                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="17px"
                    fontStyle="normal"
                    fontWeight="400"
                    paddingLeft="4px"
                    paddingRight="30px">
                    {item.text}
                  </Typography>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        {/* end why gg hospital-section */}

        {/* start meet our specialist-section */}
        {/* <Grid container className={styles.MeetOurSpecialist}>
          <Grid item xs={12} className={styles.MeetOurSpecialistfistsection}>
            <Typography
              color="var(--dark, #211E1E)"
              fontFamily="Inter"
              fontStyle="normal"
              letterSpacing="0.75px"
              sx={{
                fontSize: { xs: "24px", sm: "24px", md: "35px" },
                fontWeight: "700",
              }}>
              Meet Our Specialist
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.MeetOurSpecialistfistsection}>
            <Typography
              color="var(--dark, #211E1E)"
              fontFamily="Inter"
              fontSize=""
              fontStyle="normal"
              fontWeight="400"
              sx={{
                fontSize: { xs: "14px", sm: "18px", md: "20px" },
                fontWeight: "400",
                opacity: "0.5",
              }}>
              We have the best Doctors in GG
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                marginTop: "",
                marginBottom: "36px",
                fontSize: { xs: "0px", sm: "0px", md: "20px", lg: "20px" },
              }}
              spacing={3}
              display="flex">
              {doctorData.map(
                (
                  { GG_ID, Department_ID, Name, Designation, Qualification },
                  index
                ) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    spacing={3}
                    className={styles.SpecialistSectionContainer}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <img
                          src={`${imageBaseUrl}/${GG_ID}.jpg`}
                          alt=""
                          className={styles.SpecialistImage}
                          onError={(e) =>
                            (e.target.src = "/doctors/no_image.jpg")
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="var(--dark, #211E1E)"
                          fontFamily="Inter"
                          fontSize="25px"
                          fontStyle="normal"
                          fontWeight="600"
                          letterSpacing="0.6px"
                          lineHeight="133.523%"
                          textAlign="center">
                          {Name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: "" }}>
                        <Typography
                          color="var(--dark, #211E1E)"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="133.523%"
                          textAlign="center"
                          sx={{ opacity: "0.5" }}>
                          {Designation}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={12} className={styles.ViewAllButtonGrid}>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "6px",
            }}
            className={styles.ViewAllButton}
            onClick={handleSpecialistViewAllClick}>
            View All
          </Button>
        </Grid> */}
        {/* end meet our specialist-section */}

        {/* start facilities & activities-section */}
        <Grid container className={styles.FacilitiesAndActivities}>
          <Grid
            item
            xs={12}
            className={styles.FacilitiesAndActivitiesfistsection}>
            <Typography
              color="var(--dark, #211E1E)"
              fontFamily="Inter"
              fontSize="35px"
              fontStyle="normal"
              fontWeight="700"
              letterSpacing="0.75px">
              Facilities & Activities
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid style={{ display: "flex", justifyContent: "end" }}>
              <LiaLongArrowAltLeftSolid
                size={30}
                onClick={NormalSwiperhandlePrevious}></LiaLongArrowAltLeftSolid>
              <LiaLongArrowAltRightSolid
                size={30}
                onClick={NormalSwiperhandleNext}
                style={{ width: "50px" }}></LiaLongArrowAltRightSolid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <NormalSwiper swiperRef={normalswiperRef} />
          </Grid>
        </Grid>
        {/* end  facilities & activities-section */}
      </Container>
      <div className={styles.HomeSecondSection}>
        <Container component="main" maxWidth={false} style={{ width: "90%" }}>
          {/* start you may be interested-section */}
          <Grid container className={styles.YouMayInterested}>
            <Grid
              item
              xs={12}
              className={styles.FacilitiesAndActivitiesfistsection}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="35px"
                fontStyle="normal"
                fontWeight="700"
                letterSpacing="0.75px">
                You may be Interested
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid style={{ display: "flex", justifyContent: "end" }}>
                <LiaLongArrowAltLeftSolid
                  size={30}
                  onClick={
                    InterestedSwiperhandlePrevious
                  }></LiaLongArrowAltLeftSolid>
                <LiaLongArrowAltRightSolid
                  size={30}
                  onClick={InterestedSwiperhandleNext}
                  style={{ width: "50px" }}></LiaLongArrowAltRightSolid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MySwiper
                departments={departmentData}
                swiperRef={interestedswiperRef}
                onClickReadmore={handleDepartmentReadmoreClick}
              />
            </Grid>
          </Grid>
          {/* end you may be interested-section */}
          {/* start subscribe-section */}
          <Grid container className={styles.Subscribesection}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={styles.Subscribeleftsection}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="30px"
                fontStyle="normal"
                fontWeight="700"
                letterSpacing="0.6px">
                Subscribe our <br></br>Newsletter Sign up
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={styles.Subscriberightsection}>
              <FormControl
                sx={{
                  width: "500px",
                }}
                variant="standard">
                <InputLabel sx={{ backgroundColor: "white" }}>
                  <Typography
                    color="#000"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="300"
                    sx={{ opacity: "0.30000001192092896" }}>
                    Enter your mail id
                  </Typography>
                </InputLabel>
                <Input
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        sx={{
                          marginBottom: "14px",
                          backgroundColor: "var(--green, #17C1A3)",
                          width: "154px",
                          Height: "55px",
                          borderRadius: "6px",
                          "&:hover": {
                            backgroundColor: "var(--green, #17C1A3)",
                          },
                        }}>
                        <Typography
                          color="#FFF"
                          fontFamily="Inter"
                          fontStyle="normal"
                          fontSize="16px"
                          textAlign="center"
                          fontWeight="500"
                          textTransform="none">
                          Subscribe
                        </Typography>
                      </Button>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            {/* {openPopup && <AdsPopup onclose={closePopup} />} */}
          </Grid>
          {/* end subscribe-section */}
        </Container>
      </div>
      <SocialMediaEmbeded />
    </div>
  );
}

export default Home;
