import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Divider from "@mui/material/Divider";
import { borderRadius, height } from "@mui/system";
import { Container, List, ListItem, ListItemText } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import styles from "./DrnbTab.module.css";

import Newsimage from "../../assets/images/newsimage1.png";
import Felloshipimage from "../../assets/images/Dnrbfellowship.jpg";

import axios from "axios";

import { useNavigate } from "react-router-dom";

const News = [
  {
    id: 1,
    heading: "Could sugar consumption impact the risk of Alzheimer's disease?",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive... ",
  },
  {
    id: 2,
    heading: "MedHeavy drinkers risk muscle loss, new study finds",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
  {
    id: 3,
    heading: "MedHeavy drinkers risk muscle loss, new study finds",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
  {
    id: 4,
    heading: "MedHeavy drinkers risk muscle loss, new study finds",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
  {
    id: 5,
    heading: "MedHeavy drinkers risk muscle loss, new study finds",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
  {
    id: 6,
    heading: "MedHeavy drinkers risk muscle loss, new study finds",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
];

const data = [
  ["Number of Seats", "2"],
  ["Eligibility", "MD/DNB-RADIOLOGY or </br> DMRD (with One Year Experience)"],
  ["Stipend/month", "INR 1,00,000/-"],
  ["Course Duration", "18 Months"],
  ["Course Fee", "INR 3,00,000/-"],
];

function DrnbTab() {
  const [value, setValue] = React.useState("1");
  const [newsMediaData, setNewsMediaData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();

  const fetchDepartmentDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/newsMedia/getAll`);
      setNewsMediaData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchDepartmentDataApi();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReadmoreClick = (heading) => {
    navigate(`/newsandmedia/${heading}`);
  };

  const handleApplyNowClick = (id) => {
    navigate(`/academicsandresearch/radiologyfellowship`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
      }}>
      <TabContext value={value}>
        <Box
          sx={{
            filter: "drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.03))",
            backgroundColor: "#FFF;",
            display: "flex",
            justifyContent: "center",
            filter: "drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.03))",
            borderRadius: "35px",
          }}>
          <TabList
            onChange={handleChange}
            // aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{
              "& .MuiTab-root": {
                color: "Black",
                opacity: "0.5", // Set the tab color to the default text color
                "&.Mui-selected": {
                  color: "Black",
                  opacity: "1", // Set the active tab color to your desired color
                },
              },
            }}>
            <Tab label="GG Hospital" value="5" sx={{ textTransform: "none" }} />
            <div
              style={{
                backgroundColor: "#D3D3D3",
                width: "2px",
                height: "18px",
                justifySelf: "center",
                alignSelf: "center",
              }}></div>
            <Tab
              label="DNB Critical Care"
              value="2"
              sx={{ textTransform: "none" }}
            />
            <div
              style={{
                backgroundColor: "#D3D3D3",
                width: "2px",
                height: "18px",
                justifySelf: "center",
                alignSelf: "center",
              }}></div>
            <Tab
              label="DNB General Medicine"
              value="3"
              sx={{ textTransform: "none" }}
            />
            <div
              style={{
                backgroundColor: "#D3D3D3",
                width: "2px",
                height: "18px",
                justifySelf: "center",
                alignSelf: "center",
              }}></div>
            <Tab
              label="DNB Obstetrics and Gynaecology"
              value="4"
              sx={{ textTransform: "none" }}
            />
            <div
              style={{
                backgroundColor: "#D3D3D3",
                width: "2px",
                height: "18px",
                justifySelf: "center",
                alignSelf: "center",
              }}></div>
            <Tab
              label="Fellowship in vascular and Interventional Radiology "
              value="1"
              sx={{ textTransform: "none" }}
            />
            <div
              style={{
                backgroundColor: "#D3D3D3",
                width: "2px",
                height: "18px",
                justifySelf: "center",
                alignSelf: "center",
              }}></div>
            <Tab label="Media" value="6" sx={{ textTransform: "none" }} />
          </TabList>
        </Box>
        <TabPanel value="5">
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                GG Hospital is one of the fastest growing multi-super speciality
                hospitals in South Kerala equipped with ultra-modern
                state-of-the-art technological facilities and the most modern
                infrastructural support along with its committed and
                hard-working, most experienced faculties in various super
                specialities and they are excellent clinicians too. The history
                made by GG Hospital in the Modern Medicine field for the last 4
                years is remarkable and equally beneficial for the public as
                well as for young medical professionals for the fulfilment of
                their academic and clinical knowledge development in the field
                of practice of medicine and practice of various modern medical
                specialities.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                GG Hospital is presently operating with,MDICU, CCU, PICU, NICU,
                SICU, DIALYSIS UNIT, SPECIALISED CHEMOTHERAPY DAY CARE UNIT,
                STATE OF THE ART THEATRE COMPLEX, CATH LAB WHICH IS PERFORMING
                INTERVENTIONAL CARDIOLOGY PROCEDURES, INTERVENTIONAL NEUROLOGY,
                NEURO RADIOLOGICAL PROCEDURES AND 24*7 EMERGENCY SERVICES.
                <br></br> GG Hospital is the best affordable hospital in South
                Kerala it is NABH accredited hospital and provides a
                comprehensive spectrum of advanced medical and surgical
                interventions with a perfect mix of Inpatient & Outpatient
                services to patients of all age groups.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Functionaries of the Hospital
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Dr Sheeja G Manoj- Managing Director{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Dr Jeevan MK- Medical Superintendent & DNB Course Director{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Dr T P Madhu Sudhanan – HOD, Senior Consultant -Critical Care &
                Academic Programme Coordinator{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Dr Chithra Sivakumar- Academic Administrator{" "}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginTop="50px"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%"
              >
                1.DrNB Critical Care
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="100px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                For queries contact:<br></br> Dr Chithra Sivakumar <br></br>
                Academic Administrator & HR Doctors Affairs <br></br>{" "}
                Ph:9995229798 <br></br> GG Hospital
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                DNB Critical Care Medicine or Doctorate of National Board in
                Critical Care Medicine also known as DNB in Critical Care
                Medicine is a super speciality level course for doctors in India
                that is done by them after completion of their post-graduate
                medical degree course. The duration of this super speciality
                course is 3 years, and it focuses on the study of patients who
                have sustained or are at risk of sustaining acutely
                life-threatening single or multiple organ failures due to
                diseases or injury.
                <br></br> <br></br> Total Seats- 2
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Faculties:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                <ul>
                  <li>
                    {" "}
                    Dr T P Madhusudhanan -MBBS, DA(Anaesthesiology), MD
                    (Anaesthesiology)
                  </li>
                  <li> Dr Rajesh S -MBBS, DNB Medicine, FNB(Critical Care)</li>
                  <li>
                    Dr Jithu K V -MBBS, MD(Anaesthesiology & Critical Care)
                  </li>
                  <li>Dr Vimal V V -MBBS, DA, MD(Anaesthesiology)</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="3">
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                DNB General Medicine or Diplomate of National Board in General
                Medicine also known as DNB in General Medicine is a Postgraduate
                level course for doctors in India that is done by them after
                completion of their MBBS. The duration of this postgraduate
                course is 3 years, and it focuses on the study of various
                concepts related to the field of identification and diagnosis of
                diseases by using the theoretical knowledge and clinical
                experience acquired during the period of training.
                <br></br> <br></br> Total Seats- 2
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Faculties:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                <ul>
                  <li>Dr . Sreeveena -MBBS, MD(General Medicine)</li>
                  <li> Dr Raina T Pillai -MBBS, MD(General Medicine)</li>
                  <li>Dr Dakshina Prasad- MBBS, MD(General Medicine)</li>
                  <li>Dr Jacob John -MBBS, MD( General Medicine)</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="4">
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                DNB Obstetrics and Gynaecology or Diplomate of National Board in
                Obstetrics and Gynaecology also known as DNB in Obstetrics and
                Gynaecology is a Postgraduate level course for doctors in India
                that is done by them after completion of their MBBS. The
                duration of this post graduate course is 3 years and it focuses
                on the study of various concepts related to the field of
                diagnosis and management of Antenatal, Intra-natal, and
                Post-natal periods of normal and abnormal pregnancy and
                labor.National Board of Examinations in Medical Sciences (NBEMS)
                has given Accreditation to the department of{" "}
                <strong>Obstetrics and Gynaecology </strong>at{" "}
                <strong>
                  {" "}
                  GG Hospital, Murinjapalam Junction, Medical College Post,
                  Trivandrum, Kerala
                </strong>
                for a period of upto five (05) years i.e.{" "}
                <strong>JANUARY 2023 </strong>to <strong>DECEMBER 2027 </strong>
                to provide Post Graduate training facilities for
                <strong>
                  {" "}
                  1 (One) DNB- Post MBBS and 1 (One)DNB- Post Diploma Seat(s){" "}
                </strong>
                each year in the specialty.
                <br></br> <br></br> Seats: DNB OBG(Post MBBS)-1, DNB OBG(Post
                Diploma)-1
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                lineHeight="100%">
                Faculties:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                marginBottom="25px"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="180%">
                <ul>
                  <li>
                    DR. A NAZEEMA, MBBS (1974); DGO (1984); MD (1985)- Senior
                    Consultant-HOD OBG
                  </li>
                  <li>
                    {" "}
                    DR. ANITHA S PILLAI, MBBS (2002); MD OBG (2007);DGO OBG
                    (2006); FMAS OBG (2020)-Senior Consultant-OBG
                  </li>
                  <li>
                    DR. SUJATHA Y, MBBS (1980); DGO OBG (1983); MD OBSTETRICS
                    AND GYNAECOLOGY (1987)- Senior Consultant-OBG
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="1">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
              }}>
              <div className="fellowship">
                <Typography fontFamily="Inter" fontSize="16px" fontWeight="400">
                  GG Hospital in association with Sree Gokulam Medical College &
                  Research Foundation, Trivandrum jointly invites applications
                  for Fellowship in Vascular & Interventional Radiology (FVIR)
                  beginning from April 2024.
                </Typography>
                <Typography
                  variant="h5"
                  fontFamily="Inter"
                  fontSize="25px"
                  fontWeight="600"
                  marginTop="20px">
                  The Course Curriculum Includes :
                </Typography>
                <Grid item xs={12} marginTop="20px">
                  <Typography
                    variant="subtitle1"
                    marginBottom="25px"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="100%">
                    Vascular Interventions
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      {" "}
                      Diagnostic: Neuro (DSA/4 vessel angio), Peripheral and
                      Visceral angiography
                    </li>
                    <li>
                      Embolization: Bronchial, Gastrointestinal, hepatic,
                      splenic, renal, uterine, prostate, peripheral arteries,
                      head and neck
                    </li>
                    <li>TACE and Pre-op embolisations</li>
                    <li>
                      Recanalization procedures including thrombolysis,
                      thrombectomy and angioplasty and stenting for peripheral
                      vessels
                    </li>
                    <li>
                      Dialysis access interventions: Permacath placement, AV
                      fistula: in-flow and out-flow augmentation, Salvage
                      failing and thrombosed AVFs, Central venous stenosis
                      management.
                    </li>
                    <li>HVPG measurements and Hepato-portal interventions</li>
                    <li>
                      Endo-venous ablative (Laser/RFA/MWA) and non-ablative
                      techniques (Glue/Sclerotherapy) for varicose veins
                    </li>
                    <li>
                      DVT Interventions: Thrombolysis, IVC filters placement and
                      retrieval.
                    </li>
                    <li>Gonadal vein embolisation.</li>
                  </ul>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    marginBottom="25px"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="100%">
                    Non Vascular Interventions
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      {" "}
                      Percutaneous CT/USG guided biopsy and drainage procedures
                    </li>
                    <li>Ablation of liver/renal/lung tumors</li>
                    <li>Biliary interventions - PTBD, PCC, Stenting</li>
                    <li>
                      Urological interventions - PCN and Antegrade DJ Stenting
                    </li>
                    <li>Pain management procedures</li>
                    <li>
                      Intra-operative and pre-operative site marking/fiducial
                      marking-localization procedures.
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    marginBottom="25px"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="100%">
                    The academic curriculum
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      The academic curriculum includes weekly interventional
                      case presentation, interdepartmental meetings, seminars,
                      journal club, conference presentations and publications.
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} marginTop="50px">
                  {" "}
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {data.map((row) => (
                          <TableRow key={row[0]}>
                            <TableCell
                              dangerouslySetInnerHTML={{ __html: row[0] }}
                            />
                            <TableCell
                              dangerouslySetInnerHTML={{ __html: row[1] }}
                            />
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} marginTop="20px">
                  <Typography
                    variant="subtitle1"
                    marginBottom="25px"
                    fontFamily="Inter"
                    fontSize="20px"
                    marginTop="30px"
                    fontWeight="600"
                    lineHeight="100%">
                    Programme Director :
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="100%">
                    Dr. Praveen Kesav R MD(AIIMS), EBIR, EDIR, DICR
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400">
                    Senior Consultant & Head of Division of Interventional
                    Radiology,<br></br> Department of Diagnostic &
                    Interventional Radiology <br></br>Sree Gokulam Medical
                    College & Research Foundation and GG Hospital, Trivandrum{" "}
                    <br></br>
                    Mob: +91 99400 66090.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontFamily="Inter"
                    fontSize="20px"
                    marginTop="40px"
                    fontWeight="500"
                    lineHeight="100%">
                    <strong>Last Date to Apply: </strong> 1st March 2024
                    <br></br>
                    <br></br> <strong> Examination and Interview: </strong> 5th
                    March 2024
                    <br></br>
                    <br></br> <strong>Course starts on:</strong> 1st April 2024
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    marginTop="30px">
                    Interested candidates can submit updated CV to{" "}
                    <a href="mailto:  praveenkesav@gg-hospital.com">
                      {" "}
                      praveenkesav@gg-hospital.com
                    </a>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  marginTop="50px"
                  marginBottom="50px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                  {" "}
                  <a href="mailto:praveenkesav@gg-hospital.com?subject=Applications%20for%20Fellowship%20in%20Vascular%26InterventionalRadiology">
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                      }}
                      className={styles.ViewAllButton}
                      // onClick={() => {
                      //   handleApplyNowClick();
                      // }}
                    >
                      Apply now
                    </Button>{" "}
                  </a>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="6">
          {" "}
          <Grid container className={styles.Card} spacing={2}>
            {newsMediaData.map(
              ({ Id, Headline, CoverImage, Content, Date, Author }, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ marginTop: "60px" }}>
                  <Grid container>
                    {" "}
                    <Grid item xs={12}>
                      <img
                        src={`/News/NewsCover/${Headline}.jpg`}
                        alt=""
                        className={styles.SwiperImage}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "20px" }}>
                      <Typography
                        fontFamily="Inter"
                        fontSize="23px"
                        fontStyle="normal"
                        fontWeight="600">
                        {Headline}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "4px" }}>
                      <Typography
                        fontFamily="Inter"
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="400"
                        letterSpacing="0.36px">
                        {Content}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ marginTop: "15px", cursor: "pointer" }}
                      onClick={() => {
                        handleReadmoreClick(Headline);
                      }}>
                      <Typography
                        color="#17C1A3"
                        fontFamily="Inter"
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="400"
                        letterSpacing="0.36px">
                        {Date}
                      </Typography>
                      <Typography
                        color="#17C1A3"
                        fontFamily="Inter"
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="400"
                        letterSpacing="0.36px"
                        className={styles.DotctorsTalk}>
                        Read More
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default DrnbTab;
