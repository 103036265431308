import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import styles from "./NewsAndMedia.module.css";
import axios from "axios";

import YoutubeVideos from "../../components/YoutubeVideos/YoutubeVideos";

function NewsAndMedia() {
  const navigate = useNavigate();
  const [newsMediaData, setNewsMediaData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchDepartmentDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/newsMedia/getAll`);
      setNewsMediaData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchDepartmentDataApi();
  }, []);

  const handleReadmoreClick = (heading) => {
    navigate(`/newsandmedia/${heading}`);
  };

  // Sorting function for the newsMediaData array by Id
  const sortNewsData = (data) => {
    return data.sort((a, b) => b.Id - a.Id);
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "250px",
      }}>
      <Grid
        container
        sx={{
          marginTop: "163px",
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="65px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="1.3px">
            News
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="65px"
            fontStyle="normal"
            fontWeight="700"
            letterSpacing="1.3px">
            & Media
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="0.36px">
            In the medical arena, our success is ultimately based on the
            medically indicated quality diagnostic practices that have remained
            true to the ethical principles of the medical profession.
          </Typography>
        </Grid>
      </Grid>
      <YoutubeVideos />
      <Grid container marginTop="20px">
        <Typography
          color="#211E1E"
          fontSize="39px"
          fontFamily="Inter"
          fontWeight="400"
          wordWrap="break-word"
          display="flex"
          justifyContent="center"
          marginBottom="30px"
          textAlign="center">
          <span style={{ fontWeight: "600" }}>News</span>
        </Typography>
      </Grid>
      <Grid container className={styles.whyMsigmaCarouselCard} spacing={3}>
        {sortNewsData(newsMediaData).map(
          ({ Id, Headline, CoverImage, Content, Date, Author }, index) => (
            <Grid item key={Id} xs={12} sm={12} md={6} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <img
                    src={`/News/NewsCover/${Headline}.jpg`}
                    alt=""
                    className={styles.SwiperImage}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Typography
                    fontFamily="Inter"
                    fontSize="23px"
                    fontStyle="normal"
                    fontWeight="600">
                    {Headline}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "4px" }}>
                  <Typography
                    fontFamily="Inter"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="400"
                    letterSpacing="0.36px">
                    {Content}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginTop: "15px", cursor: "pointer" }}
                  onClick={() => {
                    handleReadmoreClick(Headline);
                  }}>
                  <Typography
                    color="#17C1A3"
                    fontFamily="Inter"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="400"
                    letterSpacing="0.36px">
                    {Date}
                  </Typography>
                  <Typography
                    color="#17C1A3"
                    fontFamily="Inter"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="400"
                    letterSpacing="0.36px"
                    className={styles.DotctorsTalk}>
                    Read More
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Container>
  );
}

export default NewsAndMedia;
