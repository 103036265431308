import React from "react";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import styles from "./Gallery.module.css";

import GalleryImage from "../../components/GalleryImage/GalleryImage";

import SindhuImage from "../../assets/images/pvsindhu.png";

const events = [
  {
    id: 1,
    heading: "PV Sindhu at GG Hospital ",
    image: SindhuImage,
  },
  {
    id: 2,
    heading: "Rashtriya Nirman Ratan Award ",
    image: SindhuImage,
  },
  {
    id: 3,
    heading: "CSR at GG Hospital ",
    image: SindhuImage,
  },
  {
    id: 4,
    heading: "Rashtriya Nirman Ratan Award ",
    image: SindhuImage,
  },
];

function Gallery() {
  const navigate = useNavigate();

  const handleHeadImageClick = (heading) => {
    navigate(`/gallery/${heading}`);
  };
  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", marginTop: "170px", marginBottom: "170px" }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="90px">
          <Typography
            fontFamily="Inter"
            fontSize="45px"
            fontStyle="normal"
            fontWeight="400"
            color="var(--dark, #211E1E)">
            GALLERY
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} height="661px">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={8}>
              {/* <img src={SindhuImage} alt="" /> */}
              <GalleryImage
                image={SindhuImage}
                width={"100%"}
                height={"100%"}
                btnName={"Childrens Day at GG Hospital"}
                onImageClick={() =>
                  handleHeadImageClick("Childrens Day at GG Hospital")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <img src={SindhuImage} alt="" /> */}
                  <GalleryImage
                    image={SindhuImage}
                    width={"100%"}
                    height={"100%"}
                    btnName={"Childrens Day at GG Hospital"}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <img src={SindhuImage} alt="" /> */}
                  <GalleryImage
                    image={SindhuImage}
                    width={"100%"}
                    height={"100%"}
                    btnName={"Childrens Day at GG Hospital"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            {events.map((event) => (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GalleryImage
                  image={event.image}
                  width={"100%"}
                  height={"100%"}
                  btnName={event.heading}
                />{" "}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Gallery;
