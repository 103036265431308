import React from "react";

import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import styles from "./Checkup.module.css";

const imagePath = "/PremitiveCheckup";

// const imageArray = [
//   { id: 1, image: `${imagePath}/Health Packages WO Price_page-0001.jpg` },
//   { id: 2, image: `${imagePath}/Health Packages WO Price_page-0002.jpg` },
//   { id: 3, image: `${imagePath}/Health Packages WO Price_page-0003.jpg` },
// ];

function CheckUp() {
  const generateImageArray = (imagePath, count) => {
    const imageArray = [];
    for (let i = 1; i <= count; i++) {
      imageArray.push({
        id: i,
        image: `${imagePath}/Health Packages WO Price_page-${String(i).padStart(
          4,
          "0"
        )}.jpg`,
      });
    }
    return imageArray;
  };

  const imageArray = generateImageArray(imagePath, 42);
  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", display: "flex", flexDirection: "column" }}
      className={styles.container}>
      <Grid
        container
        // sx={{
        //   marginTop: "104px",
        // }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="65px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="1.3px">
            Preventive
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="65px"
            fontStyle="normal"
            fontWeight="700"
            letterSpacing="1.3px">
            Checkup Directory
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          {" "}
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="0.36px">
            Carefully chosen technology acquisitions and undoubtedly the result
            of hard work and consistent delivery of excellent services by our
            staff members. In the medical arena, our success is ultimately based
            on the medically indicated quality diagnostic practices that have
            remained true to the ethical principles of the medical profession.
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid
        container
        sx={{
          marginTop: "81px",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {" "}
        <Grid item>
          {" "}
          <img
            src={Image1}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image2}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image3}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image4}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image5}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image6}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image7}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image8}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image9}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image10}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image11}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image12}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image13}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image14}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <img
            src={Image15}
            alt=""
            style={{
              maxWidth: "100%",
              height: "100%",
            }}
          />
        </Grid>
      </Grid> */}
      <Grid
        container
        sx={{
          marginTop: "81px",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {imageArray.map((item) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}>
            <img key={item.id} src={item.image} alt={`Image ${item.id}`} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default CheckUp;
