import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import styles from "./NavBar.module.css";

import gglogo from "../../assets/images/gglogo.png";
import hamburgericon from "../../assets/images/hamburgericon.png";
import TopBar from "../TopBar/TopBar";

import { useLocation, useNavigate } from "react-router-dom";

const pages = [
  { title: "Home", path: "" },
  { title: "About Us", path: "about" },
  { title: "Patient Care", path: "patientcare" },
  { title: "Department", path: "departments" },
  {
    title: "Preventive Checkup Directory",
    path: "departments/preventivecheckupdirectory",
  },
  { title: "Academics & Research", path: "academicsandresearch" },
  { title: "News & Media", path: "newsandmedia" },
  { title: "Careers", path: "careers" },
  { title: "Contact Us", path: "contactus" },
];

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [activePage, setActivePage] = React.useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1100);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [1]);
  useEffect(() => {
    const currentParent = location.pathname.split("/")[1];
    const matchedPage = pages.find((page) => currentParent === page.path);
    if (matchedPage) {
      // console.log("matched" + matchedPage.title);
      setActivePage(matchedPage.title);
    } else {
      setActivePage(null);
    }
  }, [location.pathname]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePageClick = (page) => {
    // setActivePage(page.title);
    navigate(`/${page.path}`);
  };

  const handleMainlogoClick = () => {
    navigate("/");
  };

  const [menuanchorEl, setmenuAnchorEl] = React.useState(null);
  const open = Boolean(menuanchorEl);
  const handleClick = (event) => {
    setmenuAnchorEl(event.currentTarget);

    handlePageClick({ title: "Department", path: "departments" });
  };
  const handleClose = () => {
    setmenuAnchorEl(null);
  };
  const handleCheckup = () => {
    handlePageClick({
      title: " Preventive Checkup Directory",
      path: "departments/preventivecheckupdirectory",
    });
    handleClose();
  };

  return (
    <AppBar
      position="fixed"
      style={{ background: "white", color: "black" }}
      sx={{
        borderBottom: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
      }}>
      {isLargeScreen && <TopBar />}
      <Container maxWidth={false} sx={{ marginTop: "10px" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "64px",
            marginRight: "64px",
          }}
          className={styles.toolpbar}>
          <img
            src={gglogo}
            alt=""
            className={styles.gglogo}
            onClick={handleMainlogoClick}
          />

          <Box
            sx={{
              flexGrow: 1,
              gap: "3%",
              display: {
                xs: "none",
                md: "none",
                lg: "flex",
                justifyContent: "end",
              },
            }}>
            <Button
              onClick={() => handlePageClick({ title: "Home", path: "" })}
              sx={{
                my: 2,
                color: activePage === "Home" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}>
              {" "}
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                Home
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handlePageClick({ title: "About Us", path: "about" })
              }
              sx={{
                my: 2,
                color: activePage === "About Us" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}>
              {" "}
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                About Us
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handlePageClick({ title: "Patient Care", path: "patientcare" })
              }
              sx={{
                my: 2,
                color: activePage === "Patient Care" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}>
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                Doctors
              </Typography>
            </Button>
            {/* <Button
              onClick={() =>
                handlePageClick({ title: "Department", path: "department" })
              }
              sx={{
                my: 2,
                color: activePage === "Department" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}
            >
              Department
            </Button> */}

            <Button
              disableElevation
              sx={{
                my: 2,
                color: activePage === "Department" ? "red" : "black",

                textTransform: "none",
              }}
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}>
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                Departments
              </Typography>
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={menuanchorEl}
              open={open}
              onClose={handleClose}>
              {/* <MenuItem
                  disableRipple
                  onClick={() =>
                    handlePageClick({ title: "Department", path: "department" })
                  }
                >
                  Depatment
                </MenuItem> */}
              <MenuItem onClick={handleCheckup} disableRipple>
                Preventive Checkup Directory
              </MenuItem>
            </Menu>

            <Button
              onClick={() =>
                handlePageClick({
                  title: "Academics & Research",
                  path: "academicsandresearch",
                })
              }
              sx={{
                my: 2,
                color: activePage === "Academics & Research" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}>
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                Academics & Research
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handlePageClick({ title: "News & Media", path: "newsandmedia" })
              }
              sx={{
                my: 2,
                color: activePage === "News & Media" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}>
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                News & Media
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handlePageClick({ title: "Careers", path: "careers" })
              }
              sx={{
                my: 2,
                color: activePage === "Careers" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}>
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                Careers
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handlePageClick({ title: "Contact Us", path: "contactus" })
              }
              sx={{
                my: 2,
                color: activePage === "Contact Us" ? "red" : "black",
                display: "block",
                textTransform: "none",
              }}>
              <Typography fontSize="17px" fontFamily="Inter" fontWeight="500">
                Contact Us
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                md: "flex",
                lg: "none",
                justifyContent: "flex-end",
              },
            }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              {/* <MenuIcon /> */}
              <img
                src={hamburgericon}
                alt=""
                style={{ width: "30px", height: "30px" }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {
                  xs: "block",
                  md: "block",
                  lg: "none",
                },
              }}>
              {pages.map((page) => (
                <MenuItem
                  key={page.title}
                  onClick={() => {
                    handlePageClick(page);
                    handleCloseNavMenu();
                  }}>
                  <Typography
                    textAlign="center"
                    style={{
                      color: activePage === page.title ? "red" : "inherit",
                    }}>
                    {page.title}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
