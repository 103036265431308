import React from "react";
import { useParams } from "react-router-dom";

import styles from "./Adminstration.module.css";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//images
import ChairmanImage from "../../assets/images/chairman.png";
import ViceChairmanImage from "../../assets/images/vicechairman.png";
import ManagingDirectorImage from "../../assets/images/md.png";
import Quote from "../../assets/images/golulamgopalanquote.png";

function Adminstration() {
  const { name } = useParams();

  const Administration = [
    {
      id: 1,
      profilephoto: ChairmanImage,
      quote: Quote,
      name: "Sri Gokulam Gopalan",
      writes: ` Born in the year 1944, <strong>Sri. Ambalathil Meethal Gopalan </strong>, who won the hearts of public, affectionately referred to as <strong>“Gokulam Gopalan” </strong>, the founder of India’s Iconic Brand “Sree Gokulam”. A business doyen and a humanitarian hailing from Vadakara, Kerala.<br>
      Sharing his vision for the future he has invested heavily in education and healthcare. Under his abled leadership this vision became a reality in the form of<strong> GG Hospital </strong>, a quaternary care super specialty hospital in Trivandrum, that housed specialist and superspecialist second to none with ultra-modern facilities and treatment at affordable cost.`,
      secondwrites: `<br>Being the flagship hospital of the prestigious Gokulam Group, GG Hospital has six fully-equipped operation theatres with laminar air-flow and sophisticated HEPA filters, to ensure complete infection-free & safe environment and most modern technological advancements in the healthcare domain including 128 slice CT, 4d scan and full-fledged Cath Lab for Angiography And Angioplasty with E.C.G & ECHO.The facilities are designed and equipped to meet the requirements of both Domestic and International patients. GG Hospital also boasts a renowned and expert team of doctors from all around the world and paramedical staff who delivers unparelled medical standards and unmatched patient care.<br>
      The vision of GG Hospital hospital is to provide quality health care at an affordable cost to all sections of our society thus upholding the overall motto of <strong>Love & Care to Humanity</strong>.`,
    },

    {
      id: 2,
      profilephoto: ViceChairmanImage,
      quote: "",
      name: "Dr. K K Manojan",
      writes:
        "Translating the vision of our Chairman into reality defines the dedicated efforts of our Vice Chairman, Dr. K K Manojan. He not only leads from the front, but believes in participatory approach and carrying his entire team of GG Hospital forward. As an eminent professional, he not only understands the intricacies of developing GG Hospital, but his decisions are directed to ensure compassionate, quality healthcare to our patients. Since its inception, he has left no stone unturned in steering GG Hospital to greater heights through his forward-thinking leadership and has set himself as a role model.",
    },
    {
      id: 3,
      profilephoto: ManagingDirectorImage,
      quote: "",
      name: "Dr. Sheeja G Manoj",
      writes: `Dr. Sheeja G Manoj is the Managing Director of GG Hospital, a Sister Concern of Sree Gokulam Medical College and the Deputy Director of Sree Gokulam Medical College, Trivandrum. GG Hospital is acclaimed as the pioneer corporate destination for common people under Sree Gokulam Group. A proponent of integrated healthcare, GG Hospital has a robust presence across the healthcare spectrum in the early days of its inception under her tremendous leadership. Dr. Sheeja holds MBBS and MD in Radio-diagnosis. Additionally, she is qualified with MBA in Hospital Administration.<br></br>

      Spearheading the strategic functions and operations of GG Hospital, Greatly inspired by her father and founding Chairman, Sri. Gokulam Gopalan, Dr. Sheeja was instrumental in bringing the concept of “Corporate health care destination for Common People” first time into the healthcare scenario of India and subsequently she is facilitating highest standards of health care reliably, safety and qualitatively in an advanced and cost effective way. Now, with a clear focus in making the organizations future ready, Dr. Sheeja is heading efforts to move GG Hospital into a digitally enabled organization.<br></br>
      
      Dr. Sheeja works closely with the organization’s clinicians and administration staff in introducing modern protocols to continuously improve qualitative clinical outcomes. She leads the drive on continuous quality improvement processes to achieve the highest standards of patient satisfaction.<br></br>
      
      Committed to service, Dr. Sheeja provides leadership and direction to several social projects and women empowerment forums.
      For the outstanding contribution in the field of women empowerment and corporate leadership through health care administration, Dr. Sheeja is recognized with <strong> Rashtriya Nirman Ratan Award in 2019 </strong>.`,
    },
  ];

  const adminData = Administration.find((admin) => admin.name === name);

  if (!adminData) {
    // Handle the case when 'name' doesn't match any data
    return <div>Admin not found</div>;
  }

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", marginTop: "170px" }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={adminData.profilephoto}
            className={styles.ProfileImage}
            alt=""
          />

          <Typography fontFamily="Inter" fontSize="18px" fontWeight="600">
            {adminData.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="180%"
            dangerouslySetInnerHTML={{ __html: adminData.writes }}
          ></Typography>
          <img src={adminData.quote} style={{ width: "100%" }} alt="" />
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="180%"
            dangerouslySetInnerHTML={{ __html: adminData.secondwrites }}
          ></Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Adminstration;
