import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import styles from "./Doctor.module.css";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

import DoctorImage from "../../assets/images/doctor1.png";
const doctorsImagePath = "/doctors";

const Data = [
  {
    name: "Dr. Tony Parayil Joseph",
    specialty: "Endocrinology | Consultant",
    qualifications:
      "MBBS | MD(General Medicine) | DNB (General Medicine) | DM (Endocrinology)",
    opTimes: [
      { day: "Monday", time: "9:30 AM to 3:30 PM" },
      { day: "Wenesday", time: "9:30 AM to 3:30 PM" },
      { day: "Friday", time: "9:30 AM to 3:30 PM" },
      // Add more days and time slots as needed
    ],
    image: DoctorImage,
  },
  // Add data for other doctors if you have multiple doctors
];

function Doctor() {
  const { name } = useParams();
  const [doctorsData, setDoctorsData] = useState([]);
  const [doctorsTiming, setDoctorsTiming] = useState([]);
  const [filteredDoctorTimings, setFilteredDoctorTimings] = useState([]);
  const [filteredDoctorData, setFilteredDoctorData] = useState([]);
  const [additional, setAdditionalFields] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  console.log(filteredDoctorData);

  useEffect(() => {
    const fetchDoctorsTimings = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/doctors/getTimings`
        );
        setDoctorsTiming(response?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    const fetchDoctorsData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/doctors/getAll`);
        setDoctorsData(response?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    fetchDoctorsData();
    fetchDoctorsTimings();
  }, []);

  const handleBookAppointment = (id) => {
    navigate(`/bookappointment/${id}`);
    console.log(id);
  };

  useEffect(() => {
    if (doctorsTiming.length > 0 && doctorsData.length > 0) {
      const filteredDoctor = doctorsData.find((doctor) => doctor.Name === name);

      if (filteredDoctor) {
        const filteredTimings = doctorsTiming
          .filter((timing) => timing?.Doctor === name)
          .map((timing) => {
            const dayTimings = [];

            if (timing.Monday)
              dayTimings.push({
                day: "Monday",
                time: timing.Monday !== "" ? timing.Monday : "",
              });
            if (timing.Tuesday)
              dayTimings.push({
                day: "Tuesday",
                time: timing.Tuesday !== "" ? timing.Tuesday : "",
              });
            if (timing.Wednesday)
              dayTimings.push({
                day: "Wednesday",
                time: timing.Wednesday !== "" ? timing.Wednesday : "",
              });
            if (timing.Thursday)
              dayTimings.push({
                day: "Thursday",
                time: timing.Thursday !== "" ? timing.Thursday : "",
              });
            if (timing.Friday)
              dayTimings.push({
                day: "Friday",
                time: timing.Friday !== "" ? timing.Friday : "",
              });
            if (timing.Saturday)
              dayTimings.push({
                day: "Saturday",
                time: timing.Saturday !== "" ? timing.Saturday : "",
              });
            if (timing.Sunday)
              dayTimings.push({
                day: "Sunday",
                time: timing.Sunday !== "" ? timing.Sunday : "",
              });

            return dayTimings;
          });

        setFilteredDoctorTimings(filteredTimings[0]);

        const additionalFields = doctorsTiming
          .filter((timing) => timing?.Doctor === name)
          .map((timing) => ({
            Additional: timing.Additional || "",
          }));
        console.log("additionalFields==>", additionalFields);

        setFilteredDoctorData(filteredDoctor);
        setAdditionalFields(additionalFields);
      }
    }
  }, [doctorsTiming, doctorsData, name]);

  console.log("filtered Doctor Timings ===> ", filteredDoctorTimings);

  if (!filteredDoctorData) {
    // Handle the case when 'name' doesn't match any data
    return (
      <Grid style={{ width: "90%", marginTop: "70px" }}>Doctor not found</Grid>
    );
  }

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", marginTop: "170px", marginBottom: "170px" }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <img
            src={`${doctorsImagePath}/${filteredDoctorData?.GG_ID}.jpg`}
            alt=""
            className={styles.Doctor_doctorImage__YSKuW}
            onError={(e) => (e.target.src = "/doctors/no_image.jpg")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
          //   marginTop="20px"
          className={styles.secondSection}>
          <Typography
            fontFamily="Inter"
            fontSize="24px"
            fontStyle="normal"
            fontWeight="600"
            color="var(--dark, #211E1E)">
            {filteredDoctorData?.Name}
          </Typography>
          <Typography
            variant="subtitle1"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="500"
            fontStyle="normal"
            lineHeight="180%"
            marginBottom="15px">
            {filteredDoctorData?.Designation}
          </Typography>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            className={styles.qualification}>
            {filteredDoctorData?.Qualification}
          </Typography>
          <Divider style={{ width: "100%" }} light />
          <Typography
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="600"
            marginTop="10px">
            OP TIME
          </Typography>
          <Grid
            container
            // alignItems="center"
            // justifyContent="center"
            className={styles.opTimings}
            spacing={2}>
            {filteredDoctorTimings.map((opTime, index) => (
              <Grid item xs={6} sm={4} md={4} lg={4} key={index}>
                <Typography
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  marginTop="10px">
                  {opTime.day}
                </Typography>
                <Chip label={opTime.time} variant="outlined" />
              </Grid>
            ))}
          </Grid>
          <br />
          <Divider style={{ width: "100%" }} light />
          <Typography
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="600"
            marginTop="10px">
            {additional.length > 0 ? additional[0]?.Additional : ""}
          </Typography>
          <Grid marginTop="20px">
            <Button
              variant="contained"
              size="large"
              className={styles.BookAppoinmentButton}
              sx={{
                textTransform: "none",
                borderRadius: "6px",
              }}
              onClick={() => handleBookAppointment(filteredDoctorData.GG_ID)}>
              Book an Appointment
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Doctor;
