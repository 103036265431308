import React, { useState } from "react";
import styles from "./GalleryImage.module.css";
import doctor from "../../assets/images/doctor1.png";

import { Button, Typography } from "@mui/material";

function GalleryImage({ image, width, height, btnName, onImageClick }) {
  const [hovered, setHovered] = useState(true);

  const handleHover = () => {
    setHovered(false);
  };

  const handleMouseLeave = () => {
    setHovered(true);
  };

  return (
    <div
      className={styles.card}
      style={{ width: width, height: height }}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}>
      <img src={image} className={styles.CardImg} onClick={onImageClick} />
      {hovered && (
        <div className={styles.CarBody}>
          <div>
            <Typography
              color="#FFF"
              fontFamily="Inter"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="600"
              textAlign="center"
              letterSpacing="0.696px"
              sx={{ textTransform: "none" }}>
              {btnName}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default GalleryImage;
