import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import styles from "./IndividualCareer.module.css";
import sendEmail from "../../utils/Email";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";

const Careerdetails = [
  {
    id: 1,
    post: "Staff Nurse (NICU, OT, Labour Room)",
    designation: "Staff Nurse - NICU, OT, LABOUR ROOM",
    qualification: "Bsc/GNM",
    experience: "Minimum 1 year",
  },
  {
    id: 2,
    post: "Medical Gastroenterologist",
    designation: "Consultant",
    qualification: "M.B.B.S, M.D, DM / DNB / Fellowship in Gastroenterology",
    experience: "Freshers / Experienced",
  },
  {
    id: 3,
    post: "Neurologist",
    designation: "Senior Registrar / Consultant",
    qualification: "M.B.B.S, M.D, D.M",
    experience: "Freshers / Experienced",
  },
  {
    id: 4,
    post: "Ayurvedic Doctor",
    designation: "Senior Registrar / Consultant",
    qualification: "B.A.M.S, M.D",
    experience: "Freshers / Experienced",
  },
];

function IndividualCareer() {
  const { id } = useParams();
  const [careersData, setCareersData] = useState([]);
  const [cv, setCv] = useState(null);
  const [image, setImage] = useState(null);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [position, setPosition] = useState("");
  const [subject, setSubject] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [cvErrorMessage, setCvErrorMessage] = useState("");
  const [imageErrorMessage, setImageErrorMessage] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isPhoneNumberValid, setIsNumberValid] = useState(true);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
  ];
  const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png"];

  const fetchCareersDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/careers/getAll`);
      setCareersData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchCareersDataApi();
  }, []);

  const Career = careersData.find((career) => career.Id === id);
  if (!Career) {
    // Handle the case when post doesn't match any data
    return (
      <Grid style={{ width: "90%", marginTop: "70px" }}>Career not found</Grid>
    );
  }

  // Image upload
  const handleImageChange = (e) => {
    let image = e.target.files[0];
    if (image) {
      if (image && allowedImageTypes.includes(image.type)) {
        setImage(image);
        setImageErrorMessage("");
        // Additional handling logic for valid image file
      } else {
        setImage(null);
        setImageErrorMessage(
          "Invalid file type. Please select a valid JPEG or PNG file"
        );
      }
    }
  };

  // File upload
  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (file && allowedFileTypes.includes(file.type)) {
        // Handle the valid file here
        setCv(file);
        setCvErrorMessage("");
      } else {
        setCv(null);
        setCvErrorMessage(
          "Invalid file type. Please select a PDF, DOCX, DOC, or TXT file."
        );
      }
    }
  };

  // Email Validation
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
  };

  // Full name validation
  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);
    // Check if Full name is not empty
    setIsFullNameValid(fullName.trim() !== "");
  };

  // Phone number validation
  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);

    const phoneNumberRegex = /^\d{10}$/; // Assuming a 10-digit phone number
    setIsNumberValid(phoneNumberRegex.test(newPhoneNumber));
  };

  // Creating Form Data
  function createFormData() {
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("emailId", emailId);
    formData.append("position", position);
    formData.append("subject", subject);
    formData.append("coverLetter", coverLetter);
    formData.append("careerId", id);
    formData.append("image", image);
    formData.append("cv", cv);

    return formData;
  }

  // Submit API call
  async function submitCareerApplication(formData) {
    await sendEmail(formData, "New Career Application on GG Website"); //TODO error&loading
    return await fetch(`${apiBaseUrl}/api/careers/add`, {
      method: "POST",
      body: formData,
    });
  }

  // Handling success
  function handleSuccess(data) {
    toast.success(data?.message);
    resetFormFields();
    redirectToCareersPage();
  }

  // Handling error
  function handleError() {
    toast.error("An error occurred. Please try again later.");
  }

  // Form reset
  function resetFormFields() {
    setFullName("");
    setEmailId("");
    setPhoneNumber("");
    setCoverLetter("");
    setSubject("");
    setPosition("");
    setImage(null);
    setCv(null);
  }

  // Page redirect Function
  function redirectToCareersPage() {
    window.location.href = "/careers";
  }

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();

    try {
      const response = await submitCareerApplication(formData);

      if (response.ok) {
        const data = await response.json();
        handleSuccess(data);
      } else {
        handleError();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const isSubmitDisabled =
    !isEmailValid ||
    !isFullNameValid ||
    !isPhoneNumberValid ||
    coverLetter === "" ||
    subject === "" ||
    position === "";

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        marginTop: "170px",
      }}>
      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
        >
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="34px"
            fontStyle="normal"
            fontWeight="600"
            // marginTop="10px"
          >
            {Career.Post}
          </Typography>
          <Grid container spacing={3} marginTop="20px">
            <Grid item lg={4}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="600"
                // marginTop="10px"
              >
                Destination
              </Typography>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                // marginTop="10px"
              >
                {Career.Designation}
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="600"
                // marginTop="10px"
              >
                Qualification
              </Typography>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                // marginTop="10px"
              >
                {Career.Qualification}
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="600"
                // marginTop="10px"
              >
                Experience
              </Typography>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                // marginTop="10px"
              >
                {Career.ExperienceNeed}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: "100%", marginTop: "30px" }} light />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
        >
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="34px"
            fontStyle="normal"
            fontWeight="500"
            // marginTop="10px"
          >
            Apply Now
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="400"
            // marginTop="10px"
          >
            Please complete the form below to apply for the position with us
          </Typography>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            marginTop="10px"
            marginBottom="90px">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} marginTop="10px">
                <TextField
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={fullName}
                  onChange={handleFullNameChange}
                  error={!isFullNameValid}
                  helperText={
                    !isFullNameValid ? "Full name cannot be empty" : ""
                  }
                  sx={{
                    // width: "350px",
                    height: "77px",
                    "& input": { backgroundColor: "transparent" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6} marginTop="10px">
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={!isPhoneNumberValid}
                  helperText={
                    !isPhoneNumberValid
                      ? "Please enter a valid 10-digit phone number"
                      : ""
                  }
                  sx={{
                    // width: "350px",
                    height: "77px",
                    "& input": { backgroundColor: "transparent" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6} marginTop="10px">
                <TextField
                  label="Email Id"
                  variant="outlined"
                  fullWidth
                  value={emailId}
                  onChange={handleEmailChange}
                  error={!isEmailValid}
                  helperText={
                    !isEmailValid ? "Please enter a valid email address" : ""
                  }
                  sx={{
                    // width: "350px",
                    height: "77px",
                    "& input": { backgroundColor: "transparent" },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} lg={6} marginTop="10px">
                <TextField
                  label="Applying for Position"
                  variant="outlined"
                  fullWidth
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  sx={{
                    // width: "350px",
                    height: "77px",
                    "& input": { backgroundColor: "transparent" },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6} marginTop="10px">
                <TextField
                  label="Upload Photo"
                  variant="outlined"
                  type="file"
                  fullWidth
                  onChange={handleImageChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: ".jpeg,.jpg,.png" }}
                />
                {imageErrorMessage && (
                  <p style={{ color: "red" }}>{imageErrorMessage}</p>
                )}
                <Typography
                  color="var(--dark, #211E1E)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  sx={{ opacity: 0.4000000059604645 }}
                  // marginTop="10px"
                >
                  Files of type .jpg,.png only
                </Typography>
              </Grid> */}
              <Grid item xs={12} lg={6} marginTop="10px">
                <TextField
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  sx={{
                    // width: "350px",
                    height: "77px",
                    "& input": { backgroundColor: "transparent" },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} lg={6} marginTop="10px">
                <TextField
                  label="Upload Resume"
                  variant="outlined"
                  type="file"
                  accept=".pdf"
                  fullWidth
                  onChange={handleFileChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: ".pdf,.docx,.doc,.txt" }}
                />
                {cvErrorMessage && (
                  <p style={{ color: "red" }}>{cvErrorMessage}</p>
                )}
                <Typography
                  color="var(--dark, #211E1E)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  sx={{ opacity: 0.4000000059604645 }}
                  // marginTop="10px"
                >
                  Files of type .doc,.pdf,.txt,.docx
                </Typography>
              </Grid> */}
              <Grid item xs={12} lg={12} marginTop="10px">
                {" "}
                <TextField
                  label="Cover Letter"
                  multiline
                  rows={3}
                  variant="outlined"
                  fullWidth
                  value={coverLetter}
                  onChange={(e) => setCoverLetter(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                marginTop="10px"
                display="flex"
                justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "6px",
                  }}
                  className={styles.ViewAllButton}
                  // disabled={isSubmitDisabled}
                  onClick={handleSubmit}>
                  Apply Now
                </Button>
                {/* Toast notifications */}
                <Toaster position="top-right" reverseOrder={false} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default IndividualCareer;
