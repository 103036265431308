import React from "react";
import { Link } from "react-router-dom";

import "./TopBar.css";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

import instalogo from "../../assets/images/instalogo.png";
import linkedinlogo from "../../assets/images/linkedinlogo.png";
import facebooklogo from "../../assets/images/facebooklogo.png";
import youtubelogo from "../../assets/images/youtubelogo.png";
import emaillogo from "../../assets/images/emaillogo.png";
import phonelogo from "../../assets/images/phonelogo.png";

function TopBar() {
  return (
    <Grid
      className="container"
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ height: "45px" }}>
      <Grid item xs={4} className="firstsession">
        <Grid
          container
          direction="row"
          alignContent="center"
          justifyContent="flex-start"
          spacing={1}>
          <Grid item style={{ paddingLeft: "120px" }}>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              follow us on:
            </Typography>
          </Grid>
          <Grid item>
            <Link
              to="https://www.linkedin.com/company/sreegokulamgghospital/"
              style={{
                color: "#000",
                textDecoration: "none",
                cursor: "pointer",
              }}>
              <img src={linkedinlogo} alt="" style={{ marginRight: "6px" }} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="https://www.youtube.com/channel/UCtCjvDYpiZc46Kj_ZsdBTzg"
              style={{
                color: "#000",
                textDecoration: "none",
                cursor: "pointer",
              }}>
              <img src={youtubelogo} alt="" style={{ marginRight: "6px" }} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="https://www.instagram.com/gg_hospital/"
              style={{
                color: "#000",
                textDecoration: "none",
                cursor: "pointer",
              }}>
              <img src={instalogo} alt="" style={{ marginRight: "6px" }} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="https://www.facebook.com/gghospitaltvm/"
              style={{
                color: "#000",
                textDecoration: "none",
                cursor: "pointer",
              }}>
              <img
                src={facebooklogo}
                alt=""
                style={{
                  marginRight: "6px",
                  filter: "grayscale(1)",
                }}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={8}
        className="secondsession"
        style={{ paddingRight: "120px" }}>
        <Grid
          container
          direction="row"
          alignContent="center"
          justifyContent="flex-end"
          spacing={1}>
          <Grid item>
            <img src={phonelogo} alt="" style={{ marginRight: "6px" }} />
          </Grid>
          <Grid item style={{}}>
            <Typography style={{ fontSize: "14px" }}>
              0471 2779100 | 0471 2779101 | 0471 4299299
            </Typography>
          </Grid>
          <Grid item style={{ paddingLeft: "30px" }}>
            <img src={emaillogo} alt="" style={{ marginRight: "6px" }} />
          </Grid>
          <Grid item style={{}}>
            <Typography style={{ fontSize: "14px" }}>
              gghospitalphkpl@gmail.com | mdoffice@gg-hospital.com
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TopBar;
