import axios from "axios";

const FORM_URL = `https://submit-form.com/${process.env.REACT_APP_FORMSPARK_ID}`;

export default async function sendEmail(data, emailSubject) {
  try {
    //TODO
    if (data.values !== undefined) {
      //If received FormData
      data = Object.fromEntries(data);
    }
    await axios.post(FORM_URL, {
      ...data,
      _email: {
        from: "GG Website",
        subject: emailSubject || "Website Data Received",
      },
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
