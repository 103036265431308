import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import styles from "./ContactUs.module.css";

import MapImage from "../../assets/images/contactusmapimage.png";

function ContactUs() {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isPhoneNumberValid, setIsNumberValid] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // Email Validation
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
  };

  // Full name validation
  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);
    // Check if first name is not empty
    setIsFullNameValid(fullName.trim() !== "");
  };

  // Phone number validationF
  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);
    // Assuming a 10-digit phone number
    const phoneNumberRegex = /^\d{10}$/;
    setIsNumberValid(phoneNumberRegex.test(newPhoneNumber));
  };

  const isSubmitDisabled =
    !isEmailValid ||
    !isFullNameValid ||
    !isPhoneNumberValid ||
    subject === "" ||
    message === "";

  // Creating Form Data
  function createFormData() {
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("number", phoneNumber);
    formData.append("emailId", emailId);
    formData.append("message", message);
    formData.append("subject", subject);
    return formData;
  }

  // Submit API call
  async function submitFormData(formData) {
    return await fetch(`${apiBaseUrl}/api/masters/addInquiry`, {
      method: "POST",
      body: formData,
    });
  }

  // Handling success
  function handleSuccess(data) {
    toast.success(data?.message);
    resetFormFields();
    resetValidationFlags();
    setIsFormSubmitted(true);
  }

  // Handling error
  function handleError() {
    toast.error("An error occurred. Please try again later.");
  }

  // Form reset
  function resetFormFields() {
    setEmailId("");
    setFullName("");
    setPhoneNumber("");
    setMessage("");
    setSubject("");
  }

  // Reset Validations
  function resetValidationFlags() {
    setIsEmailValid(true);
    setIsFullNameValid(true);
    setIsNumberValid(true);
  }

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();

    try {
      const response = await submitFormData(formData);

      if (response.ok) {
        const data = await response.json();
        handleSuccess(data);
      } else {
        handleError();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", display: "flex", flexDirection: "column" }}>
      <Grid
        container
        sx={{
          marginTop: "170px",
          marginBottom: "50px",
        }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="45px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="0.9px"
            textAlign="center">
            Contact Us
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          {" "}
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="0.36px"
            textAlign="center">
            Our success is ultimately based on the medically indicated quality{" "}
            <br></br>
            diagnostic practices that have remained true to the ethical
            principles of the medical.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        sx={{ marginTop: "42px", marginBottom: "300px" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="35px"
                fontStyle="normal"
                fontWeight="700"
                letterSpacing="0.7px">
                Send us a message
              </Typography>
            </Grid>
            <Grid item xs={6} marginTop="10px">
              <TextField
                label="Full Name"
                value={fullName}
                variant="outlined"
                fullWidth
                onChange={handleFullNameChange}
                error={!isFullNameValid}
                helperText={!isFullNameValid ? "Full name cannot be empty" : ""}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={6} marginTop="10px">
              <TextField
                label="Phone Number"
                value={phoneNumber}
                variant="outlined"
                fullWidth
                onChange={handlePhoneNumberChange}
                error={!isPhoneNumberValid}
                helperText={
                  !isPhoneNumberValid
                    ? "Please enter a valid 10-digit phone number"
                    : ""
                }
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={6} marginTop="10px">
              <TextField
                label="Email Id"
                value={emailId}
                variant="outlined"
                fullWidth
                onChange={handleEmailChange}
                error={!isEmailValid}
                helperText={
                  !isEmailValid ? "Please enter a valid email address" : ""
                }
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={6} marginTop="10px">
              <TextField
                label="Subject"
                variant="outlined"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={12} marginTop="10px">
              {" "}
              <TextField
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              marginTop="10px"
              display="flex"
              justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "6px",
                }}
                disabled={isSubmitDisabled}
                onClick={handleSubmit}
                className={styles.ViewAllButton}>
                Apply Now
              </Button>
              {/* Toast notifications */}
              <Toaster position="top-right" reverseOrder={false} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-200px",
                zIndex: "1000",
              }}>
              {" "}
              <Grid
                container
                width="460px"
                height="313px"
                bgcolor="#FFF"
                borderRadius="4px"
                boxShadow="0px 15px 23px 0px rgba(0, 0, 0, 0.08);"
                alignItems="center"
                padding="20px"
                spacing={0}>
                <Grid item sx={12}>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="25px"
                    fontStyle="normal"
                    fontWeight="600">
                    Where to find us
                  </Typography>
                </Grid>
                <Grid item sx={12}>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="600">
                    Address :
                  </Typography>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="400">
                    G G Hospital,Paragon Hospital Kerala Pvt. Ltd. Murinjapalam{" "}
                    Junction,Medical College P.O Trivandrum – 695 011
                  </Typography>
                </Grid>
                <Grid item sx={12}>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="600">
                    Emergency No :
                  </Typography>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="400">
                    0471 2779101 | 0471 2779100 | 0471 4299299
                  </Typography>
                </Grid>
                <Grid item sx={12}>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="600">
                    Email Id :
                  </Typography>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400">
                    gghospitalphkpl@gmail.com | mdoffice@gg-hospital.com
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={MapImage} alt="" className={styles.MapImage} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactUs;
