import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Badge, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "./Careers.module.css";

const Careerdetails = [
  {
    id: 1,
    post: "Staff Nurse (NICU, OT, Labour Room)",
    details:
      "Designation : Staff Nurse - NICU, OT, LABOUR ROOM | Qualification : Bsc/GNM | Experience : Minimum 1 year",
  },
  {
    id: 2,
    post: "Medical Gastroenterologist",
    details:
      "Designation : Consultant | Qualification : M.B.B.S, M.D, DM / DNB / Fellowship in Gastroenterology | Experience : Freshers / Experienced",
  },
  {
    id: 3,
    post: "Neurologist",
    details:
      "Designation : Senior Registrar / Consultant | Qualification : M.B.B.S, M.D, D.M | Experience : Freshers / Experienced",
  },
  {
    id: 4,
    post: "Ayurvedic Doctor",
    details:
      "Designation : Senior Registrar / Consultant | Qualification : B.A.M.S, M.D | Experience : Freshers / Experienced",
  },
];

function Careers() {
  const navigate = useNavigate();
  const [careersData, setCareersData] = useState([]);
  console.log(careersData);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleApplyNowClick = (id) => {
    console.log("Navigated Id==>", id);
    navigate(`/careers/${id}`);
  };

  const fetchCareersDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/careers/getAll`);
      setCareersData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchCareersDataApi();
  }, []);

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", display: "flex", flexDirection: "column" }}>
      <Grid
        container
        sx={{
          marginTop: "170px",
          marginBottom: "60px",
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="65px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="1.3px">
            Careers
          </Typography>
        </Grid>                       
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}>
          {" "}
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            letterSpacing="0.36px">
            In the medical arena, our success is ultimately based on the
            medically indicated quality diagnostic practices that have remained
            true to the ethical principles of the medical profession.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          marginTop: "81px",
          marginBottom: "500px",
        }}>
        {" "}
        {careersData
          .slice()
          .reverse()
          .map(
            (
              {
                Id,
                Post,
                Designation,
                Qualification,
                ExperienceNeed,
                HiringEmail,
                Number,
                Status,
              },
              index
            ) => (
              <Grid
                item
                className={styles.CareerCard}
                boxShadow="0px 14px 23px 0px rgba(0, 0, 0, 0.03)"
                display="flex"
                alignItems="center"
                sx={{
                  boxShadow: "0px 14px 23px 0px rgba(0, 0, 0, 0.03)",
                  marginTop: "36px",
                }}>
                <Grid container className={styles.CareerContainer} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={10} marginLeft="32px">
                    <Grid item>
                      {Status === "new" ? (
                        <Badge
                          badgeContent="New"
                          sx={{
                            "& .MuiBadge-badge": {
                              color: "white",
                              backgroundColor: "red",
                            },
                          }}></Badge>
                      ) : (
                        ""
                      )}
                      <Typography
                        color="var(--dark, #211E1E)"
                        fontFamily="Inter"
                        fontSize="24px"
                        fontStyle="normal"
                        fontWeight="600">
                        {Post}
                      </Typography>
                    </Grid>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      sx={{ marginTop: "18px" }}
                      marginTop="5px">
                      Designation: {Designation} | Qualification:{" "}
                      {Qualification} | Experience: {ExperienceNeed}
                    </Typography>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      marginTop="5px">
                      Send your CV to: {HiringEmail} | Contact Number:
                      {Number}
                    </Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="center">
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                      }}
                      className={styles.ViewAllButton}
                      onClick={() => {
                        handleApplyNowClick(Id);
                      }}>
                      Apply Now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
      </Grid>
    </Container>
  );
}

export default Careers;
