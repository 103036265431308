import React from "react";
import { useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SindhuImage from "../../assets/images/pvsindhu.png";

import GalleryImage from "../../components/GalleryImage/GalleryImage";

function IndividualGallery() {
  const { heading } = useParams();

  const events = [
    {
      id: 1,
      heading: "PV Sindhu at GG Hospital ",
      image: SindhuImage,
    },
    {
      id: 2,
      heading: "Rashtriya Nirman Ratan Award ",
      image: SindhuImage,
    },
    {
      id: 3,
      heading: "CSR at GG Hospital ",
      image: SindhuImage,
    },
  ];

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", marginTop: "170px" }}>
      <Grid container spacing={8}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
        >
          <Typography
            color="#747474"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"

            // marginTop="10px"
          >
            22 july 2022
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="35px"
            fontStyle="normal"
            fontWeight="400"
            // marginTop="10px"
          >
            PV Sindhu at GG Hospital
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            marginTop="10px"
            marginBottom="50px">
            Our Vice-Chairman, Dr Manojan and Executive Director, Dr Sheeja G
            Manoj with World Champion P V Sindhu.
          </Typography>

          <img src={SindhuImage} alt="" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
        >
          <Grid container spacing={2}>
            {events.map((event, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                flexDirection="column"
                //   alignItems="center"
                justifyContent="center">
                <Grid
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center">
                  <GalleryImage
                    image={event.image}
                    width={"80%"}
                    height={"80%"}
                    btnName={event.heading}
                  />{" "}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default IndividualGallery;
