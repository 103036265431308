import React from "react";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function PrivacyPolicy() {
  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        marginTop: "170px",
        marginBottom: "100px",
      }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
        >
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="34px"
            fontStyle="normal"
            fontWeight="600"
            // marginTop="10px"
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="180%"
            marginTop="20px">
            GG Hospital is required by law to maintain the privacy of your
            medical information, to provide you with this written Notice of
            Privacy Rights and Practices, and to abide by the terms of the
            Notice currently in effect. This policy shall be applicable to the
            information collected or displayed on our website. We assure to take
            the privacy seriously and to keep your privacy and confidentiality
            of the information provided to us. <br></br>
            <br></br>We shall not intentionally or unless required under laws
            share the contents of any person with any outside authorities or any
            third party. We do not guarantee or assure that the electronic
            communications received from you or contents or records may not be
            accessible to the third parties.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
        >
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="34px"
            fontStyle="normal"
            fontWeight="600"
            // marginTop="10px"
          >
            Disclaimer
          </Typography>
          <Typography
            variant="subtitle1"
            marginBottom="25px"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="180%"
            marginTop="20px">
            All the information displayed, transmitted or carried by GG Hospital
            and its related websites, face book page including, but not limited
            to, directories, guides, news articles, opinions, reviews, text,
            photographs, images, illustrations, profiles, audio clips, video
            clips, trademarks, service marks and the like, collectively the
            “Content”, are protected by the copyright and other intellectual
            property laws and be informed that the content of the same is not
            intended to be a substitute for professional medical advice and not
            for solicitation of business. The Content is owned by GG Hospital,
            its affiliates or third party licensors. You may not modify,
            publish, transmit, transfer, sell, reproduce, create derivative work
            from, distribute, repost, perform, display or in any way
            commercially exploit any of the Content. You agree to abide by all
            copyright notices and restrictions attached to any Content accessed
            through the GG Hospital website, face book page and not to alter the
            content in any way. Permitted Use you may take a single copy of the
            Content displayed on the GG Hospital for personal, non-commercial
            use only, provided that you do not remove any trademarks, copyright
            and any other notice contained in such Content. You shall not
            archive or retain any Content in any form without written
            permission. The information provided in this site for the sole
            purpose of disseminating health information for public benefit.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PrivacyPolicy;
